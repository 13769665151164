import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { RootState } from '@/stores/store';

import { Notification } from './Notification';
import { dismissNotification, NotificationProps } from './notificationsSlice';

export const Notifications = () => {
	const dispatch = useDispatch();
	const onDismiss = (id: string) => {
		dispatch(dismissNotification(id));
	};
	const { notifications } = useSelector((state) => (state as RootState).notifications);
	const filteredNotificaitons = notifications.filter(
		(notification: NotificationProps, index: number, self: NotificationProps[]) =>
			index ===
			self.findIndex(
				(n) => JSON.stringify(n.message) === JSON.stringify(notification.message)
			)
	);
	const location = useLocation();
	// surpress notification errors on public facing survey pages as they are displayed differently
	if (location.pathname?.includes('/survey/')) return null;
	return (
		<>
			<div
				aria-live='assertive'
				className='fixed inset-0 flex flex-col items-end px-4 py-6 pointer-events-none sm:items-start sm:p-6 z-40'
			>
				<div className='flex flex-col items-center space-y-4 w-full  sm:items-end'>
					{filteredNotificaitons.map((notification: NotificationProps) => {
						return (
							<Notification
								key={notification.id}
								notification={notification}
								onDismiss={onDismiss}
							/>
						);
					})}
				</div>
			</div>
		</>
	);
};
