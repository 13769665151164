import { TypographyProps } from '@@/components/Elements/Typography';
import clsx from 'clsx';

export type ApplicationBarProps = {
	children: React.ReactElement | string;
	className?: string;
};

export const ApplicationBar = (props: TypographyProps) => {
	const { children, className } = props;
	return (
		<header
			className={clsx(
				'flex flex-row w-screen box-border shrink-0 fixed top-0 left-auto right-0 z-20',
				className
			)}
		>
			<div className='min-h-fit w-screen pl-6 pr-6 relative flex justify-between items-center h-20 bg-primaryBackground border-b-2 border-secondaryBorder'>
				{children}
			</div>
		</header>
	);
};
