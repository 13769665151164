import { Button } from '@@/components/Elements';
import clsx from 'clsx';
import { Close, Menu } from 'mdi-material-ui';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { useFetchUserQuery } from '@/api';
import iconMenuActiveHome from '@/assets/menu/icon_menu_active_home.svg';
import iconMenuHome from '@/assets/menu/icon_menu_default_home.svg';
import iconMenuHoverHome from '@/assets/menu/icon_menu_hover_home.svg';
import { MenuItem } from '@/components/Elements/MenuItem';
import { resetState } from '@/components/PatientDataTable/store/dataTableSlice';
import { menuConfigObject } from '@/config/menuConfig';
import { trackEvent } from '@/lib/analytics';
import { getFilteredRoles } from '@/utils';

export interface DrawerProps {
	className?: string;
	close: () => void;
	config: menuConfigObject[];
	isOpen: boolean;
	open: () => void;
}

export const Drawer = (props: DrawerProps) => {
	const { className, close, config, isOpen, open } = props;

	const dispatch = useDispatch();
	const { data: userData } = useFetchUserQuery();
	const userFeatures = userData?.features;
	const filteredRoles = getFilteredRoles(userFeatures);

	const handleClick = () => {
		dispatch(resetState());
		trackEvent('Sidebar menu item clicked');
	};

	const HomeMenuItem = () => {
		return (
			<NavLink
				to='/'
				title='Home'
				// eslint-disable-next-line react/no-children-prop
				children={({ isActive }) => {
					return (
						<MenuItem
							isExpanded={isOpen}
							icon={iconMenuHome}
							iconActive={iconMenuActiveHome}
							iconHover={iconMenuHoverHome}
							isActive={isActive}
							className='mt-2'
							onClick={!isActive ? handleClick : undefined}
						>
							Home
						</MenuItem>
					);
				}}
				data-test-id='home-menu-item'
			></NavLink>
		);
	};

	const renderHomeMenuItem = () => {
		// Users with 'internal_study_admin' role have access to both HCI and Reporting feature so we show them the Home Menu Item
		if (
			userFeatures &&
			userFeatures.length === 1 &&
			(userFeatures[0] === 'internal_study_admin' || userFeatures[0] === 'manual_screening')
		)
			return <HomeMenuItem />;

		// Users with 'study_progress_metrics' & 'patient_status_metrics' roles only have access to the Reporting feature so we don't render the Home Menu Item
		if (
			userFeatures &&
			userFeatures.length === 2 &&
			userFeatures.includes('study_progress_metrics') &&
			userFeatures.includes('patient_status_metrics')
		)
			return false;
		if (userFeatures && userFeatures.length > 1) return <HomeMenuItem />;
	};

	const renderMenuItems = () => {
		return filteredRoles?.map((menuItem, i) => {
			const { icon, link, testId, title } = menuItem;
			return (
				<NavLink
					key={i}
					to={link}
					title={title}
					// eslint-disable-next-line react/no-children-prop
					children={({ isActive }) => {
						return (
							<MenuItem
								isExpanded={isOpen}
								icon={icon.nav}
								iconActive={icon.navActive}
								iconHover={icon.navHover}
								isActive={isActive}
								className='mt-2'
								onClick={!isActive ? handleClick : undefined}
							>
								{title}
							</MenuItem>
						);
					}}
					data-test-id={`${testId}-menu-item`}
				></NavLink>
			);
		});
	};

	const closeButtonClick = () => {
		close();
		trackEvent('Sidebar menu closed');
	};

	const renderCloseButton = () => {
		return (
			<Button variant='icon' size='icon' name='drawer_close' onClick={closeButtonClick}>
				<span className='sr-only'>close menu</span>
				<Close className='h-6 w-6 text-secondaryTextDark' />
			</Button>
		);
	};

	const openButtonClick = () => {
		open();
		trackEvent('Sidebar menu expanded');
	};

	const renderOpenButton = () => {
		return (
			<Button
				variant='icon'
				size='icon'
				name='drawer_open'
				onClick={openButtonClick}
				aria-label='menu'
			>
				<Menu className='w-6 h-6 text-secondaryTextDark' />
			</Button>
		);
	};

	return (
		<div>
			<aside
				className={clsx(
					'bg-primaryBackground border-r-2 border-secondaryBorder h-screen min-h-screen pt-5 transition-width ease-in-out delay-100 duration-300 overflow-x-hidden z-30',
					isOpen ? 'w-[240px]' : 'w-[64px]',
					className
				)}
			>
				<div className={clsx('flex flex-row justify-end mr-2')}>
					{isOpen ? renderCloseButton() : renderOpenButton()}
				</div>
				<nav className='mx-2 mt-7'>
					{renderHomeMenuItem()}
					{!config ? null : renderMenuItems()}
				</nav>
			</aside>
		</div>
	);
};
