import {
	createListenerMiddleware,
	isAnyOf,
	isRejectedWithValue,
	Middleware,
	MiddlewareAPI,
} from '@reduxjs/toolkit';

import { addNotification } from '@/components/Notifications/notificationsSlice';
import {
	saveBatchRowSelection,
	saveColumnVisibility,
	saveFilterValues,
	saveOrdering,
	saveSearchValue,
	saveSelectedTab,
	saveOrderType,
	saveOrderedColumn,
	saveStudyId,
	saveTableScrollPosition,
	saveRowsTotal,
	savePatientId,
	savePatientStatus,
	saveTrialSiteId,
	savePageNumber,
	savePageSize,
} from '@/components/PatientDataTable/store/dataTableSlice';
import { formatErrorCodes } from '@/utils';

import type { RootState } from './store';

export const listenerMiddleware = createListenerMiddleware();
listenerMiddleware.startListening({
	matcher: isAnyOf(
		saveBatchRowSelection,
		saveColumnVisibility,
		saveFilterValues,
		saveOrdering,
		saveSearchValue,
		saveSelectedTab,
		saveOrderType,
		saveOrderedColumn,
		saveStudyId,
		saveTrialSiteId,
		saveTableScrollPosition,
		saveRowsTotal,
		savePatientId,
		savePatientStatus,
		savePageNumber,
		savePageSize
	),
	effect: (action, listenerApi) =>
		sessionStorage.setItem(
			'dataTableState',
			JSON.stringify((listenerApi.getState() as RootState).dataTable)
		),
});
export const rtkQueryErrorHandler: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
	if (isRejectedWithValue(action) && action.payload.status !== 401) {
		if (Array.isArray(action.payload.data)) {
			action.payload.data.map((err: object) => {
				api.dispatch(
					addNotification({
						type: 'error',
						title: 'Error',
						message: formatErrorCodes({ status: action.payload.status, data: err }),
						timeoutLength: 8000,
					})
				);
			});
		} else {
			api.dispatch(
				addNotification({
					type: 'error',
					title: 'Error',
					message: formatErrorCodes(action.payload),
					timeoutLength: 8000,
				})
			);
		}
	}
	return next(action);
};
