import { Typography } from '@@/components/Elements';
import { CellContext } from '@tanstack/react-table';

import { DataTableObject } from '@/components/PatientDataTable';
import { PatientObject } from '@/types';
import { formatDate } from '@/utils';

import { FetchPatientScreeningObject } from '../types';

type CellContextExtended = CellContext<DataTableObject, string> & FetchPatientScreeningObject;

export const LastContactedCell = ({ props }: { props: CellContextExtended }) => {
	const { last_contact_at, last_contact_method } = props.row.original as PatientObject;
	const formatLastContactMethod = (data: string | null | undefined) => {
		if (!data) return null;
		if (data?.includes('_')) {
			const parts = data.split('_');
			return parts[1].charAt(0).toUpperCase() + parts[1].slice(1);
		} else {
			return data.charAt(0).toUpperCase() + data.slice(1);
		}
	};
	return (
		<>
			<div>{formatDate(last_contact_at)}</div>
			<div>
				<Typography variant='bodySmall' className='text-secondaryTextDark'>
					{formatLastContactMethod(last_contact_method)}
				</Typography>
			</div>
		</>
	);
};
