import Cookies from 'universal-cookie';
const storagePrefix = 'csrf';

const cookies = new Cookies();

const storage = {
	getCookie: () => {
		return cookies.get(`${storagePrefix}token`);
	},

	getDataTableState: () => {
		return sessionStorage.getItem('dataTableState');
	},

	clearDataTableState: () => {
		return sessionStorage.removeItem('dataTableState');
	},
};

export default storage;
