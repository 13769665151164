import { baseApi } from '@/lib/rtkQuery/baseApi';

import {
	PatientScreeningPatientsRequest,
	PatientScreeningStatsResponse,
	FetchPatientScreeningPatientSurveysRequest,
	FetchPatientScreeningPatientSurveysResponse,
	FetchAvailableSurveysRequest,
	FetchAvailableSurveysResponse,
	createSurveyAssignmentRequest,
	FetchSurveyAssignmentAnswersResponse,
	FetchSurveyAssignmentRequest,
	UpdateSurveyAssignmentRequest,
	PatientScreeningPatientSurveyObject,
	FetchPatientScreeningResponse,
	FetchTrialSiteRequest,
	TrialSiteObject,
	savePatientScreeningResultRequest,
	commitScreeningResponse,
	UpdateSurveyAssignmentResultRequest,
} from '../types';

export const patientScreeningApi = baseApi
	.enhanceEndpoints({
		addTagTypes: [
			'Patient_List',
			'Patient_List_Stats',
			'SurveyList',
			'SurveyAssignmentAnswers',
			'patient_details',
		],
	})
	.injectEndpoints({
		endpoints: (build) => ({
			fetchPatientsBasedScreeningStatus: build.query<
				FetchPatientScreeningResponse,
				PatientScreeningPatientsRequest
			>({
				query: ({
					ordering,
					page,
					page_size,
					search_value,
					study_id,
					status,
					cancelled,
				}) => {
					const encoded_search_value = encodeURIComponent(
						search_value ? search_value : ''
					);
					const status_query = status ? `status=${status}` : `status=`;
					const cancelled_query = cancelled ? `cancelled=${cancelled}` : `cancelled=`;
					return {
						url: `v1/patient-screening/?page=${page}&page_size=${page_size}&study_id=${study_id}&search=${encoded_search_value}&ordering=${ordering}&${status_query}&${cancelled_query}`,
						method: 'GET',
					};
				},
				transformResponse: (response: FetchPatientScreeningResponse) => {
					return Array.isArray(response)
						? { results: response, total: response.length }
						: response;
				},
				providesTags: ['Patient_List'],
			}),
			fetchPatientScreeningStats: build.query<
				PatientScreeningStatsResponse,
				PatientScreeningPatientsRequest
			>({
				query: ({ ordering, page, page_size, search_value, study_id }) => {
					const encoded_search_value = encodeURIComponent(
						search_value ? search_value : ''
					);
					return {
						url: `v1/patient-screening/stats?page=${page}&page_size=${page_size}&study_id=${study_id}&search=${encoded_search_value}&ordering=${ordering}`,
						method: 'GET',
					};
				},
				providesTags: ['Patient_List_Stats'],
			}),
			fetchScreeningPatientSurveys: build.query<
				FetchPatientScreeningPatientSurveysResponse,
				FetchPatientScreeningPatientSurveysRequest
			>({
				query: ({ ordering, page, page_size, patient_identifier }) => {
					return {
						url: `v1/survey-assignment/?page=${page}&page_size=${page_size}&patient_id=${patient_identifier}&ordering=${ordering}`,
						method: 'get',
					};
				},
				providesTags: ['SurveyList'],
			}),
			fetchAvailableSurveys: build.query<
				FetchAvailableSurveysResponse,
				FetchAvailableSurveysRequest
			>({
				query: ({ study_id }) => {
					return {
						url: `v1/survey/version/?all=true&study_id=${study_id}&survey_type=manual_screening`,
						method: 'get',
					};
				},
				transformResponse: (response: FetchAvailableSurveysResponse) => {
					return Array.isArray(response)
						? { results: response, total: response.length }
						: response;
				},
			}),
			createSurveyAssignment: build.mutation<
				PatientScreeningPatientSurveyObject,
				createSurveyAssignmentRequest
			>({
				query: (body) => {
					return {
						url: 'v1/survey-assignment/',
						method: 'post',
						body,
					};
				},
				invalidatesTags: ['SurveyList'],
			}),
			fetchSurveyAssignmentAnswers: build.query<
				FetchSurveyAssignmentAnswersResponse,
				FetchSurveyAssignmentRequest
			>({
				query: ({ assignment_id }) => {
					return {
						url: `v1/survey-assignment/${assignment_id}/answer/`,
						method: 'get',
					};
				},
				providesTags: ['SurveyAssignmentAnswers'],
			}),
			updateSurveyAssignmentAnswers: build.mutation<
				FetchSurveyAssignmentAnswersResponse,
				UpdateSurveyAssignmentRequest
			>({
				query: ({ assignment_id, surveyData }) => {
					return {
						url: `v1/survey-assignment/${assignment_id}/answer/`,
						method: 'PATCH',
						body: surveyData,
					};
				},
				invalidatesTags: ['SurveyAssignmentAnswers', 'SurveyList'],
			}),
			fetchSurveyAssignment: build.query<
				PatientScreeningPatientSurveyObject,
				FetchSurveyAssignmentRequest
			>({
				query: ({ assignment_id }) => {
					return {
						url: `v1/survey-assignment/${assignment_id}/`,
						method: 'get',
					};
				},
			}),
			updateSurveyAssignment: build.mutation<
				PatientScreeningPatientSurveyObject,
				UpdateSurveyAssignmentResultRequest
			>({
				query: ({ assignment_id, surveyData }) => {
					return {
						url: `v1/survey-assignment/${assignment_id}/`,
						method: 'PATCH',
						body: surveyData,
					};
				},
				invalidatesTags: [
					'SurveyList',
					'patient_details',
					'Patient_List',
					'Patient_List_Stats',
				],
			}),
			fetchTrialSite: build.query<TrialSiteObject[], FetchTrialSiteRequest>({
				query: ({ study_id }) => {
					return {
						url: `v1/study-site/?all=true&study_id=${study_id}`,
						method: 'get',
					};
				},
			}),
			savePatientScreeningResult: build.mutation<void, savePatientScreeningResultRequest>({
				query: ({ patientId, study_site_id, screening_status, trial_site_note }) => {
					return {
						url: `v1/patient-screening/${patientId}/`,
						method: 'PATCH',
						body: {
							screening_status,
							study_site_id,
							trial_site_note,
						},
					};
				},
			}),
			commitScreeningResult: build.mutation<commitScreeningResponse, string>({
				query: (patientId) => {
					return {
						url: `v1/patient-screening/${patientId}/commit/`,
						method: 'PATCH',
					};
				},
				invalidatesTags: ['Patient_List', 'Patient_List_Stats'],
			}),
			setPreferredLanguage: build.mutation({
				query: ({ patient_identifier, preferred_language }) => {
					return {
						url: `v1/patients/${patient_identifier}/`,
						method: 'PATCH',
						body: {
							preferred_language,
						},
					};
				},
			}),
		}),
	});

export const {
	useFetchPatientScreeningStatsQuery,
	useFetchPatientsBasedScreeningStatusQuery,
	useFetchScreeningPatientSurveysQuery,
	useFetchAvailableSurveysQuery,
	useCreateSurveyAssignmentMutation,
	useFetchSurveyAssignmentAnswersQuery,
	useUpdateSurveyAssignmentAnswersMutation,
	useFetchSurveyAssignmentQuery,
	useUpdateSurveyAssignmentMutation,
	useFetchTrialSiteQuery,
	useSavePatientScreeningResultMutation,
	useCommitScreeningResultMutation,
	useSetPreferredLanguageMutation,
} = patientScreeningApi;
