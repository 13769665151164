import { Menu } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import { AccountOutline } from 'mdi-material-ui';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useFetchUserQuery } from '@/api';
import { addNotification } from '@/components/Notifications/notificationsSlice';
import { reset } from '@/components/PatientDataTable';
import { useLogoutUserMutation } from '@/features/auth';
import { trackEvent, trackIdentify } from '@/lib/analytics';
import { baseApi } from '@/lib/rtkQuery/baseApi';
import storage from '@/utils/storage';

export const UserMenu = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { data } = useFetchUserQuery();
	const [logoutUser, { isSuccess }] = useLogoutUserMutation();

	useEffect(() => {
		if (isSuccess) {
			dispatch(
				addNotification({
					type: 'success',
					title: 'Success',
					message: 'You have successfully logged out',
					timeoutLength: 8000,
				})
			);
			dispatch(reset());
			dispatch(baseApi.util.resetApiState());
			navigate('/', { replace: true });
		}
	}, [dispatch, isSuccess, navigate]);

	const handleLogout = () => {
		trackEvent('User logged-out');
		storage.clearDataTableState();
		logoutUser();
	};

	useEffect(() => {
		if (data) {
			// identify this user's role (available features) in analytics
			trackIdentify(data.username, {
				features: data.features,
			});
		}
	}, [data]);

	const trackMenuClick = (isOpen: boolean) => {
		const options = { isExpanded: !isOpen };
		trackEvent('User menu expanded', options);
	};

	const renderMenu = () => {
		return (
			<Menu as='div' className={clsx('relative inline-block text-left')}>
				{({ open }) => (
					<>
						<div>
							<Menu.Button
								className='inline-flex w-full justify-center items-center rounded-md px-4 py-2 text-sm font-medium text-primaryTextDark bg-primaryBackground'
								name='usermenu_open'
								onClick={() => trackMenuClick(open)}
							>
								<AccountOutline className='mr-1' aria-hidden='true' />
								<span className='hidden md:inline'>{data?.email}</span>
								<ChevronDownIcon
									className='ml-2 -mr-1 h-5 w-5 text-primaryBrand'
									aria-hidden='true'
								/>
							</Menu.Button>
						</div>
						<Menu.Items className='absolute right-0 mt-2 w-56 origin-top-right rounded-md bg-primaryBackground border-2 border-secondaryBorder'>
							<div className='px-1 py-1 '>
								<Menu.Item>
									{({ active }) => (
										<button
											className={clsx(
												'group flex w-full items-center rounded-md px-2 py-2 text-sm text-primaryTextDark',
												active ? 'bg-highlight' : ''
											)}
											name='usermenu_logout'
											onClick={handleLogout}
										>
											Logout
										</button>
									)}
								</Menu.Item>
							</div>
						</Menu.Items>
					</>
				)}
			</Menu>
		);
	};

	return <div className='flex flex-row justify-end'>{data ? renderMenu() : null}</div>;
};
