import { Typography } from '@@/components/Elements';

type ClickedThroughCellProps = {
	clickedThroughValue: number;
	engagedValue: number;
};

export const ClickedThroughCell = (props: ClickedThroughCellProps) => {
	const { clickedThroughValue, engagedValue } = props;
	const clickedThroughPercentage =
		engagedValue > 0 ? Math.round((clickedThroughValue / engagedValue) * 100) : 0;

	return (
		<>
			{clickedThroughValue}
			<Typography variant='bodySmall' className='text-secondaryTextDark'>
				{clickedThroughPercentage}%
			</Typography>
		</>
	);
};
