import { HttpErrorObject } from '@/types';

export const HTTP_ERRORS: HttpErrorObject = {
	400: 'Oops, there seems to be something wrong with the request you sent.',
	401: 'Sorry, you are not authorized to access this resource. Please check your credentials and try again.',
	403: "Sorry, you don't have permission to access this resource. Please contact the site administrator for more information.",
	404: "Oops, the resource you're looking for cannot be found. Please check the URL and try again.",
	405: 'Sorry, the HTTP method used for this request is not allowed. Please check the documentation and try again.',
	408: "Sorry, the server didn't receive a complete request within the expected time frame. Please try again.",
	500: 'Oops, something went wrong on the server side. Please try again later or contact the site administrator for assistance.',
	502: "Sorry, there seems to be a problem with the server that's acting as a gateway to another server. Please try again later.",
	503: 'Sorry, the server is currently unavailable. Please try again later or contact the site administrator for more information.',
	504: "Sorry, there seems to be a problem with the server that's acting as a gateway to another server. Please try again later.",
};
