import { Typography } from '@@/components/Elements';
import { SpinnerFixedPosition } from '@@/components/Elements/Spinner';
import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import { Notifications } from '@/components/Notifications';
import { store } from '@/stores/store';

const ErrorFallback = () => {
	return (
		<div
			className='text-error w-screen h-screen flex flex-col justify-center items-center'
			role='alert'
		>
			<Typography variant='display2'>Ooops, something went wrong :( </Typography>
		</div>
	);
};

type AppProviderProps = {
	children: React.ReactNode;
};

export const AppProvider = (props: AppProviderProps) => {
	const { children } = props;
	return (
		<React.Suspense fallback={<SpinnerFixedPosition size='xl' variant='light' />}>
			<ErrorBoundary FallbackComponent={ErrorFallback}>
				<HelmetProvider>
					<Provider store={store}>
						<Router basename={import.meta.env.VITE_URL_PREFIX}>
							<Notifications />
							{children}
						</Router>
					</Provider>
				</HelmetProvider>
			</ErrorBoundary>
		</React.Suspense>
	);
};
