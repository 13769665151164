import { Typography } from '@@/components/Elements';
import { RadioGroup } from '@headlessui/react';
import clsx from 'clsx';

import { IconRadioButton as IconButton } from './types';

type IconRadioButtonProps = {
	button: IconButton;
	checked: boolean;
};

export const IconRadioButton: React.FC<IconRadioButtonProps> = ({ button, checked = false }) => (
	<RadioGroup.Option
		key={button.label}
		value={button}
		data-test-id={`${button.label}`}
		className={clsx(
			checked ? `${button.activeClasses}` : 'text-secondaryTextDark',
			'cursor-pointer rounded px-5 py-4 border-2 border-secondaryBorder mr-6 h-[150px] w-[150px]'
		)}
	>
		{() => (
			<div className='align-middle flex flex-col h-full items-center justify-center'>
				{button.icon}
				<Typography variant='body' className='text-center'>
					{button.label}
				</Typography>
			</div>
		)}
	</RadioGroup.Option>
);
