import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

import { PrivateRoutes } from './PrivateRoutes';

const Dashboard = lazy(() =>
	import('@/features/misc').then(({ Dashboard }) => ({ default: Dashboard }))
);

const PatientScreening = lazy(() =>
	import('@/features/patientScreening').then(({ PatientScreening }) => ({
		default: PatientScreening,
	}))
);

const PatientEngagement = lazy(() =>
	import('@/features/patientEngagement').then(({ PatientEngagement }) => ({
		default: PatientEngagement,
	}))
);

const PatientContact = lazy(() =>
	import('@/features/patientContact').then(({ PatientContact }) => ({
		default: PatientContact,
	}))
);

const TrialSites = lazy(() =>
	import('@/features/trialSites').then(({ TrialSites }) => ({
		default: TrialSites,
	}))
);
const HealthCareInstitution = lazy(() =>
	import('@/features/healthCareInstitution').then(({ HealthCareInstitution }) => ({
		default: HealthCareInstitution,
	}))
);

const Creator = lazy(() =>
	import('@/features/creator').then(({ Creator }) => ({
		default: Creator,
	}))
);

const Reporting = lazy(() =>
	import('@/features/reporting').then(({ Reporting }) => ({
		default: Reporting,
	}))
);

const PatientApproval = lazy(() =>
	import('@/features/patientApproval').then(({ PatientApproval }) => ({
		default: PatientApproval,
	}))
);

const ClinicalAdmin = lazy(() =>
	import('@/features/clinicalAdmin').then(({ ClinicalAdmin }) => ({
		default: ClinicalAdmin,
	}))
);

const PatientLists = lazy(() =>
	import('@/features/patientLists').then(({ PatientLists }) => ({
		default: PatientLists,
	}))
);

export const protectedRoutes = [
	{
		path: '/',
		element: <PrivateRoutes />,
		children: [
			{ path: '/patient-contact/*', element: <PatientContact /> },
			{ path: '/patient-engagement/*', element: <PatientEngagement /> },
			{ path: '/patient-lists/*', element: <PatientLists /> },
			{ path: '/patient-screening/*', element: <PatientScreening /> },
			{ path: '/trial-sites/*', element: <TrialSites /> },
			{ path: '/creator/*', element: <Creator /> },
			{ path: '/reporting/*', element: <Reporting /> },
			{ path: '/patient-approval/*', element: <PatientApproval /> },
			{ path: '/health-care-institution/*', element: <HealthCareInstitution /> },
			{ path: '/clinical-admin/*', element: <ClinicalAdmin /> },
			{ index: true, element: <Dashboard /> },
			{ path: '*', element: <Navigate to='.' /> },
		],
	},
];
