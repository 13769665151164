import { Button, Typography } from '@@/components/Elements';
import { FormikHelpers, FormikProps } from 'formik';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { Alert } from '@/components/Elements';
import { FormContainer as Form, InputField, PasswordField } from '@/components/Form';
import { FormActions, FormValues } from '@/components/Form/types';
import { addNotification } from '@/components/Notifications/notificationsSlice';
import { baseApi } from '@/lib/rtkQuery/baseApi';
import { ErrorObj } from '@/types';

import { useLoginUserMutation } from '../api/authApi';
import { AUTH } from '../consts';
import { LoginRequest } from '../types';

const initialValues: FormValues = {
	username: '',
	password: '',
};

const schema = Yup.object().shape({
	username: Yup.string().label('Email').required(),
	password: Yup.string().label('Password').required(),
});

export const LoginForm = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [loginUser, { isSuccess, isError, error }] = useLoginUserMutation();

	const renderErrorAlert = () => {
		if (isError)
			return (
				<Alert severity='error'>
					{(error as ErrorObj).data?.detail ||
						AUTH.COMPONENTS.LOGIN.ERROR_ALERT.DESCRIPTION}
				</Alert>
			);
	};

	useEffect(() => {
		if (isSuccess) {
			dispatch(
				addNotification({
					type: 'success',
					title: AUTH.COMPONENTS.LOGIN.SUCCESS_NOTIFICATION.TITLE,
					message: AUTH.COMPONENTS.LOGIN.SUCCESS_NOTIFICATION.BODY,
					timeoutLength: AUTH.COMPONENTS.LOGIN.SUCCESS_NOTIFICATION.TIMEOUT_LENGTH,
				})
			);
			dispatch(baseApi.util.invalidateTags(['session']));
			navigate('/');
		}
	}, [dispatch, isSuccess, navigate]);

	return (
		<Form
			initialValues={initialValues}
			onSubmit={async (
				values: FormValues,
				actions: FormActions & FormikHelpers<FormValues>
			) => {
				await loginUser(values as LoginRequest);
				actions.setSubmitting(false);
			}}
			schema={schema}
			formStyles='space-y-6'
			buttonText='Sign in'
			buttonStyles='w-full'
		>
			{(formikProps: FormikProps<FormValues>) => {
				const { isSubmitting, isValid, dirty } = formikProps;
				return (
					<>
						{renderErrorAlert()}
						<InputField
							type='text'
							label='Username'
							name='username'
							width='col3'
							placeholder='Enter your username'
						/>
						<PasswordField
							label='Password'
							name='password'
							width='col3'
							placeholder='Enter your password'
						/>
						<NavLink
							to='/forgotten-password'
							className='flex items-center justify-end pt-4'
						>
							<Typography variant='link'>
								{AUTH.COMPONENTS.LOGIN.FORM.FORGOT_PASSWORD_LINK.LABEL}
							</Typography>
						</NavLink>
						<Button
							variant='primary'
							type='submit'
							isLoading={isSubmitting}
							disabled={!isValid || !dirty}
							size='lg'
						>
							{AUTH.COMPONENTS.LOGIN.FORM.SUBMIT_BUTTON.LABEL}
						</Button>
					</>
				);
			}}
		</Form>
	);
};
