import { CellContext } from '@tanstack/react-table';

import { DataTableObject } from '@/components/PatientDataTable';
import { getStatusTitle } from '@/utils/statusUtils';

import { surveyResultOptions } from '../consts';
import { FetchPatientScreeningContextObject, SurveyResultOption } from '../types';

type CellContextExtended = CellContext<DataTableObject, string> &
	FetchPatientScreeningContextObject;

export const ResultCell = ({ info }: { info: CellContextExtended }) => {
	if (info.getValue()) {
		return getStatusTitle(
			surveyResultOptions.find(
				(option: SurveyResultOption) => option.value === info.getValue()
			)?.label as string,
			info.getValue() as string
		);
	} else {
		return null;
	}
};
