import { ConfirmationDialog } from './ConfirmationDialog';
import { DefaultDialog } from './DefaultDialog';
import { ReduxDialogProps } from './dialogsSlice';

interface DialogComponentProps extends ReduxDialogProps {
	onDismiss: (id: string) => void;
}

export const DialogComponent = (props: DialogComponentProps) => {
	const { description, id, onDismiss, title, confirmButtonLabel, type } = props;
	switch (type) {
		case 'default':
			return (
				<DefaultDialog
					description={description}
					id={id}
					onDismiss={onDismiss}
					title={title}
				/>
			);
		case 'confirmation':
			return (
				<ConfirmationDialog
					description={description}
					id={id}
					onDismiss={onDismiss}
					title={title}
					confirmButtonLabel={confirmButtonLabel}
				/>
			);

		default:
			return (
				<DefaultDialog
					description={description}
					id={id}
					onDismiss={onDismiss}
					title={title}
				/>
			);
	}
};
