import { Typography } from '@@/components/Elements';

import { StudiesObject } from '@/features/patientLists';
import { PatientObject } from '@/types';

const colorVariants: { [key: string]: string } = {
	// Tailwind requires class names to be written in full for it to find them
	// add all colours used here so Tailwind can find the class names.

	// Patient status
	prospect: 'bg-prospect',
	candidate: 'bg-candidate',
	screened: 'bg-screened',
	trained: 'bg-trained',
	readyToEngage: 'bg-readyToEngage',
	completed: 'bg-completed',
	studySiteScheduled: 'bg-studySiteScheduled',
	studySiteScreening: 'bg-studySiteScreening',
	studySiteContacted: 'bg-studySiteContacted',
	statusUnknown: 'bg-statusUnknown',

	// Patient Approver Status
	approved: 'bg-approved',
	engaged: 'bg-engaged',
	excluded: 'bg-excluded',
	consented: 'bg-consented',
	randomised: 'bg-randomised',

	//Patient Screening Status
	screeningRequired: 'bg-screeningRequired',
	screeningPassed: 'bg-screeningPassed',
	screeningFailed: 'bg-screeningFailed',
	requiresReScreening: 'bg-requiresReScreening',
	consentDelegated: 'bg-consentDelegated',

	// Trial Sites Status
	new: 'bg-new',
	deceased: 'bg-deceased',
	optedOut: 'bg-optedOut',
	trialSiteContacted: 'bg-trialSiteContacted',
	trialSiteScheduled: 'bg-trialSiteScheduled',
	trialSiteScreening: 'bg-trialSiteScreening',

	// Letter Status
	scheduled: 'bg-scheduled',
	sentToPrint: 'bg-sentToPrint',
	printing: 'bg-printing',
	inThePost: 'bg-inThePost',
	inTransit: 'bg-inTransit',
	atDeloveryOffice: 'bg-atDeloveryOffice',
	delivered: 'bg-delivered',
	returned: 'bg-returned',
	letterCancelled: 'bg-letterCancelled',
	failed: 'bg-failed',

	// Mail Status
	producing: 'bg-producing',
	dispatched: 'bg-dispatched',
	mailCancelled: 'bg-mailCancelled',

	// Active Status
	active: 'bg-active',
	inactive: 'bg-inactive',
	hold: 'bg-hold',

	// Survey status
	pass: 'bg-surveyPass',
	fail: 'bg-surveyFailed',
	['re-screening']: 'bg-surveyRescreening',
	['opt-out']: 'bg-surveyOptedOut',
};

export const getStatusTitle = (title: string, color: string) => {
	return (
		<div className='flex flex-row items-center text-center'>
			<span className={`${colorVariants[color]} rounded py-1 px-3`}>
				<Typography variant='body' className='text-primaryTextDark'>
					{title}
				</Typography>
			</span>
		</div>
	);
};

export const statusIsComplete = (status: string) => {
	return ['Excluded', 'Randomised'].includes(status);
};

export const screeningIsComplete = (status: string) => {
	return [
		'Screened',
		'Screening failed',
		'Consent Delegated',
		'Contacted',
		'Scheduled',
		'Screening',
	].includes(status);
};

export const formatScreeningStatus = (
	patientData: PatientObject | StudiesObject,
	type?: 'screening' | 'trial'
) => {
	const cancelledStatus = patientData?.cancelled;
	const status = patientData?.status;

	if (cancelledStatus === 2000) {
		return {
			statusValue: 'Opted Out',
			Element: getStatusTitle('Opted out', 'optedOut'),
		};
	}

	if (cancelledStatus === 6900) {
		return {
			statusValue: 'Excluded',
			Element: getStatusTitle('Excluded', 'excluded'),
		};
	}

	if (cancelledStatus === 8000) {
		return {
			statusValue: 'Deceased',
			Element: getStatusTitle('Deceased', 'deceased'),
		};
	}

	if (status === 70 && cancelledStatus === 0) {
		return {
			statusValue: 'Prospect',
			Element: getStatusTitle('Prospect', 'prospect'),
		};
	}

	if (status === 100 && cancelledStatus === 0) {
		return {
			statusValue: 'Candidate',
			Element: getStatusTitle('Candidate', 'candidate'),
		};
	}

	if (status === 500 && cancelledStatus === 0) {
		return {
			statusValue: 'Approved',
			Element: getStatusTitle('Approved', 'approved'),
		};
	}

	if (status === 510 && cancelledStatus === 0) {
		return {
			statusValue: 'Ready to Engage',
			Element: getStatusTitle('Ready to Engage', 'readyToEngage'),
		};
	}
	if (status === 520 && cancelledStatus === 0) {
		return {
			statusValue: 'Engaged',
			Element: getStatusTitle('Engaged', 'engaged'),
		};
	}

	if (status === 530 && cancelledStatus === 0) {
		return {
			statusValue: 'Consent Delegated',
			Element: getStatusTitle(
				'Consent Delegated',
				type === 'screening' ? 'consentDelegated' : 'consentDelegated'
			),
		};
	}

	if (status === 540 && cancelledStatus === 0) {
		return {
			statusValue: 'Consent Pending',
			Element: getStatusTitle('Consent Pending', 'consentPending'),
		};
	}

	if (status === 550 && cancelledStatus === 0) {
		if (type === 'screening') {
			return {
				statusValue: 'Screening required',
				Element: getStatusTitle('Screening required', 'screeningRequired'),
			};
		}
		return {
			statusValue: 'Consented',
			Element: getStatusTitle('Consented', 'consented'),
		};
	}

	if ((status === 550 || status === 580) && cancelledStatus == 6800) {
		return {
			statusValue: 'Screening failed',
			Element: getStatusTitle('Screening failed', 'screeningFailed'),
		};
	}

	if (status === 580) {
		return {
			statusValue: 'Re-screening required',
			Element: getStatusTitle('Re-screening required', 'requiresReScreening'),
		};
	}

	if (status === 600) {
		if (type === 'screening') {
			return {
				statusValue: 'Screening passed',
				Element: getStatusTitle('Screening passed', 'screeningPassed'),
			};
		}
		if (cancelledStatus === 0) {
			return {
				statusValue: type === 'trial' ? 'New' : 'Screened',
				Element:
					type == 'trial'
						? getStatusTitle('New', 'new')
						: getStatusTitle('Screened', 'screened'),
			};
		}
	}

	if (status === 602 && cancelledStatus === 0) {
		return {
			statusValue: 'Contacted',
			Element:
				type == 'trial'
					? getStatusTitle('Contacted', 'trialSiteContacted')
					: getStatusTitle('Study Site Contacted', 'studySiteContacted'),
		};
	}

	if (status === 604 && cancelledStatus === 0) {
		return {
			statusValue: 'Scheduled',
			Element:
				type == 'trial'
					? getStatusTitle('Scheduled', 'trialSiteScheduled')
					: getStatusTitle('Study Site Scheduled', 'studySiteScheduled'),
		};
	}

	if (status === 606 && cancelledStatus === 0) {
		return {
			statusValue: 'Screening',
			Element:
				type == 'trial'
					? getStatusTitle('Screening', 'trialSiteScreening')
					: getStatusTitle('Study Site Screening', 'studySiteScreening'),
		};
	}

	if (status === 610 && cancelledStatus === 0) {
		return {
			statusValue: 'Randomised',
			Element: getStatusTitle(
				'Randomised',
				type === 'screening' ? 'randomised' : 'randomised'
			),
		};
	}

	if (status === 800 && cancelledStatus === 0) {
		return {
			statusValue: 'Trained',
			Element: getStatusTitle('Trained', 'trained'),
		};
	}

	if (status === 1000 && cancelledStatus === 0) {
		return {
			statusValue: 'Completed',
			Element: getStatusTitle('Completed', 'completed'),
		};
	}

	return {
		statusValue: patientData?.status_label,
		Element: getStatusTitle(
			type === 'screening' ? 'Status unknown' : patientData?.status_label,
			'statusUnknown'
		),
	};
};

export const formatLetterStatus = (patientData: PatientObject) => {
	const letterStatus = patientData.letter_status || '';
	if (letterStatus === 'unconfigured') {
		return {
			Element: getStatusTitle('Unconfigured', 'statusUnknown'),
		};
	}

	if (letterStatus === 'scheduled') {
		return {
			Element: getStatusTitle('Scheduled', 'scheduled'),
		};
	}

	if (letterStatus === 'failed') {
		return {
			Element: getStatusTitle('Failed', 'failed'),
		};
	}

	if (letterStatus === 'received') {
		return {
			Element: getStatusTitle('Sent to print', 'sentToPrint'),
		};
	}

	if (letterStatus === 'producing') {
		return {
			Element: getStatusTitle('Printing', 'producing'),
		};
	}

	if (letterStatus === 'handed_over') {
		return {
			Element: getStatusTitle('In the post', 'inThePost'),
		};
	}

	if (letterStatus === 'in_transit') {
		return {
			Element: getStatusTitle('In transit', 'inTransit'),
		};
	}

	if (letterStatus === 'local_delivery') {
		return {
			Element: getStatusTitle('At delivery office', 'atDeliveryOffice'),
		};
	}

	if (letterStatus === 'delivered') {
		return {
			Element: getStatusTitle('Delivered', 'delivered'),
		};
	}

	if (letterStatus === 'returned') {
		return {
			Element: getStatusTitle('Returned', 'returned'),
		};
	}

	if (letterStatus === 'cancelled') {
		return {
			Element: getStatusTitle('Cancelled', 'letterCancelled'),
		};
	}

	return {
		Element: getStatusTitle(
			letterStatus.length === 0 ? 'Status unknown' : letterStatus,
			'statusUnknown'
		),
	};
};
