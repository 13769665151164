import { Formik, Form, FormikProps, FormikHelpers } from 'formik';

import { FormActions, FormContainerFormikProps, FormContainerProps, FormValues } from './types';

export const FormContainer = (props: FormContainerProps) => {
	const {
		children,
		initialValues,
		onSubmit,
		schema,
		formStyles,
		validate,
		dataTestId = '',
	} = props;
	const handleSubmit = (values: FormValues, actions: FormActions & FormikHelpers<FormValues>) => {
		onSubmit(values, actions);
	};

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={handleSubmit}
			validationSchema={schema}
			enableReinitialize={true}
			validate={(values) => {
				validate && validate(values);
			}}
		>
			{(formikProps: FormContainerFormikProps & FormikProps<FormValues>) => {
				const { handleSubmit } = formikProps;
				return (
					<Form className={formStyles} onSubmit={handleSubmit} data-test-id={dataTestId}>
						{children(formikProps)}
					</Form>
				);
			}}
		</Formik>
	);
};
