import { SpinnerFixedPosition } from '@@/components/Elements';
import { Suspense, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';

import { useFetchFeatureFlagsQuery } from '@/api';
import { MainLayout } from '@/components/Layout';
import { saveFeatureSwitches } from '@/stores/featureFlagsSlice';

export const PrivateRoutes = () => {
	const dispatch = useDispatch();
	const { data } = useFetchFeatureFlagsQuery();

	useEffect(() => {
		if (data) {
			dispatch(saveFeatureSwitches({ featureSwitches: data?.switches }));
		}
	}, [data, dispatch]);

	return (
		<MainLayout>
			<Suspense fallback={<SpinnerFixedPosition size='xl' variant='light' />}>
				<Outlet />
			</Suspense>
		</MainLayout>
	);
};
