import { PATIENT_IDENTIFIER } from '@/i18n/consts';

export const PATIENT_DETAILS = {
	APIS: {
		PATIENTS: 'v1/patients/',
		TRAILING_SLASH: '/',
		CONTACT_LOGS: 'v1/engagements/contact-logs/',
		PATIENT_NOTES: 'v1/patient-randomising/',
		NOTE: '/note',
	},
	COMPONENTS: {
		CONTACT_HISTORY: {
			TITLE: 'Contact history',
			ADD_CONTACT_BUTTON: 'Add contact',
		},
		PATIENT_HISTORY: {
			TITLE: 'Contact history',
			ADD_CONTACT_BUTTON: 'Add contact',
		},
		PATIENT_INFORMATION: {
			TITLE: 'Personal Info',
			TABLE: {
				COLUMNS: {
					A: {
						LABEL: 'Name',
					},
					B: {
						LABEL: 'Date of birth',
					},
					C: {
						LABEL: 'Mobile number',
					},
					D: {
						LABEL: 'Second contact number',
					},
					E: {
						LABEL: 'Email',
					},
					F: {
						LABEL: PATIENT_IDENTIFIER,
					},
					G: {
						LABEL: 'Provider',
					},
					H: {
						LABEL: 'Preferred language',
					},
				},
			},
		},
		STUDY_INFORMATION: {
			TITLE: 'Study info',
		},
	},
	CONFIGS: {
		STUDY_INFORMATION: {
			TABLE: {
				COLUMNS: {
					A: {
						LABEL: 'Umed ID',
					},
					B: {
						LABEL: 'Partner ID',
					},
					C: {
						LABEL: 'Status',
					},
					D: {
						LABEL: 'Reason',
					},
				},
			},
		},
		PATIENT_HISTORY: {
			TABLE: {
				COLUMNS: {
					A: {
						LABEL: 'Date',
					},
					B: {
						LABEL: 'Note',
					},
					C: {
						LABEL: 'User',
					},
				},
			},
		},
		CONTACT_HISTORY: {
			TABLE: {
				COLUMNS: {
					A: {
						LABEL: 'Created at',
					},
					B: {
						LABEL: 'Contact method',
					},
					C: {
						LABEL: 'Made by',
					},
					D: {
						LABEL: 'Outcome',
					},
					E: {
						LABEL: 'Call booked',
					},
					F: {
						LABEL: 'Description',
					},
				},
			},
		},
	},
};
