import { getPaginationQuery } from '@/components/PatientDataTable/Utils';
import { FetchPatientListRequest, FetchPatientListResponseObject } from '@/features/patientLists';
import { baseApi } from '@/lib/rtkQuery/baseApi';

import {
	StudyProgressRequest,
	FetchStudyProgressResponse,
	FetchSurveysResponse,
	FetchSurveysRequest,
	FetchAnalyticsTokenResponse,
	FetchDashboardItemsResponse,
} from '../types';

export const reportingApi = baseApi
	.enhanceEndpoints({
		addTagTypes: [
			'Study_Progress',
			'ops_Study_Progress',
			'survey-list',
			'Patient_Status',
			'optout_patient_list',
		],
	})
	.injectEndpoints({
		endpoints: (build) => ({
			fetchStudyProgressMetrics: build.query<
				FetchStudyProgressResponse,
				StudyProgressRequest
			>({
				query: ({
					page,
					page_size,
					study_id,
					from_date,
					before_date,
					group_by,
					export_filename,
				}) => {
					const from_date_query = from_date ? `from_date=${from_date}` : `from_date=`;
					const before_date_query = before_date
						? `before_date=${before_date}`
						: `before_date=`;
					const exportFilenameQuery = export_filename
						? `&export_filename=${export_filename}`
						: '';
					const paginationQuery = getPaginationQuery(page, page_size, !!export_filename);
					return {
						url: `v1/study/${study_id}/metrics/study_progress/?${from_date_query}&${before_date_query}&group_by=${group_by}&study_id=${study_id}&ordering=group_name${paginationQuery}${exportFilenameQuery}`,
						method: 'GET',
					};
				},
				transformResponse: (response: FetchStudyProgressResponse) => {
					return Array.isArray(response)
						? { results: response, total: response.length }
						: response;
				},
				providesTags: ['Study_Progress'],
			}),
			fetchPatientStatusMetrics: build.query({
				query: ({ page, page_size, study_id, groupByPartnership, export_filename }) => {
					const group_by_partnership = groupByPartnership
						? `group_by_partnership=true`
						: '';
					const exportFilenameQuery = export_filename
						? `&export_filename=${export_filename}`
						: '';
					const paginationQuery = getPaginationQuery(page, page_size, !!export_filename);
					return {
						url: `v1/study/${study_id}/metrics/patient-status/?study_id=${study_id}&${group_by_partnership}${paginationQuery}${exportFilenameQuery}`,
						method: 'GET',
					};
				},
				transformResponse: (response: FetchStudyProgressResponse) => {
					return Array.isArray(response)
						? { results: response, total: response.length }
						: response;
				},
				providesTags: ['Patient_Status'],
			}),
			fetchABTestingMetrics: build.query<FetchStudyProgressResponse, StudyProgressRequest>({
				query: ({
					page,
					page_size,
					study_id,
					from_date,
					before_date,
					export_filename,
					show_active = 'true',
					survey_id,
				}) => {
					const from_date_query = from_date ? `from_date=${from_date}` : `from_date=`;
					const before_date_query = before_date
						? `before_date=${before_date}`
						: `before_date=`;
					const exportFilenameQuery = export_filename
						? `&export_filename=${export_filename}`
						: '';
					const paginationQuery = getPaginationQuery(page, page_size, !!export_filename);
					return {
						url: `v1/study/${study_id}/metrics/ops_study_progress/?${from_date_query}&${before_date_query}&study_id=${study_id}&survey_id=${survey_id}&ordering=message_template&show_active=${show_active}${paginationQuery}${exportFilenameQuery}`,
						method: 'GET',
					};
				},
				transformResponse: (response: FetchStudyProgressResponse) => {
					return Array.isArray(response)
						? { results: response, total: response.length }
						: response;
				},
				providesTags: ['ops_Study_Progress'],
			}),
			fetchSurveys: build.query<FetchSurveysResponse, FetchSurveysRequest>({
				query: ({ study_id }) => {
					return {
						url: `v1/survey/?all=true&study_id=${study_id}`,
						method: 'get',
					};
				},
				transformResponse: (response: FetchSurveysResponse) => {
					return Array.isArray(response)
						? { results: response, total: response.length }
						: response;
				},
				providesTags: ['survey-list'],
			}),
			fetchAnalyticsToken: build.query<FetchAnalyticsTokenResponse, void>({
				query: () => {
					return {
						url: 'v1/analytics/token/',
						method: 'get',
					};
				},
			}),
			fetchDashboardItems: build.query<FetchDashboardItemsResponse[], void>({
				query: () => {
					return {
						url: 'v1/analytics/dashboard-items/',
						method: 'get',
					};
				},
			}),
			fetchOptOutPatientList: build.query<
				FetchPatientListResponseObject,
				FetchPatientListRequest
			>({
				query: ({ search_value, page_size, page, filtersForm }) => {
					const searchValue = search_value ? search_value : '';
					return {
						url: 'v1/patient-search/',
						method: 'POST',
						body: {
							page,
							page_size,
							filters: {
								...filtersForm,
								cancelled: [2000],
								opted_out: true,
								search: searchValue,
							},
						},
					};
				},
				providesTags: ['optout_patient_list'],
			}),
		}),
	});

export const {
	useFetchStudyProgressMetricsQuery,
	useFetchABTestingMetricsQuery,
	useFetchSurveysQuery,
	useFetchPatientStatusMetricsQuery,
	useFetchAnalyticsTokenQuery,
	useFetchDashboardItemsQuery,
	useFetchOptOutPatientListQuery,
} = reportingApi;
