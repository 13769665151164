import { RadioGroup } from '@headlessui/react';

import { IconRadioButton } from './IconRadioButton';
import { IconRadioButton as IconButton } from './types';

type IconRadioGroupProps = {
	IconRadioButtons: IconButton[];
	selected?: IconButton | string;
	className?: string;
	label: string;
	onChange: (value: IconButton) => void;
};

export const IconRadioGroup: React.FC<IconRadioGroupProps> = ({
	IconRadioButtons,
	selected,
	className,
	label,
	onChange,
}) => {
	return (
		<RadioGroup
			aria-labelledby={label}
			data-test-id={`radiobuttongroup`}
			onChange={(e: IconButton) => onChange(e)}
		>
			<RadioGroup.Label className='sr-only'>{label}</RadioGroup.Label>
			<div className={className}>
				{IconRadioButtons.map((button) => (
					<IconRadioButton
						key={button.label}
						button={button}
						checked={selected === button}
					/>
				))}
			</div>
		</RadioGroup>
	);
};
