import { Typography } from '@@/components/Elements';
import { createColumnHelper } from '@tanstack/react-table';

import { Tooltip } from '@/components/Elements';

import { ClickedThroughCell } from '../components/ABMetricsView/ClickedThroughCell';
import { StudyProgressObject } from '../types';

const columnHelper = createColumnHelper<StudyProgressObject>();

export const columns = [
	columnHelper.accessor('status_candidate', {
		header: () => 'Candidate',
	}),
	columnHelper.accessor('status_engaged', {
		header: () => 'Engaged',
	}),
	columnHelper.accessor('clicked_on_link', {
		header: () => {
			return (
				<>
					Clicked Through
					<Tooltip description='What is the clicked through rate?'>
						<Typography className='font-normal'>
							Shows the percentage of people who clicked through to the survey after
							being sent the message.
						</Typography>
					</Tooltip>
				</>
			);
		},
		meta: {
			hasTooltip: true,
		},
		cell: (props) => {
			return (
				<ClickedThroughCell
					clickedThroughValue={props.getValue()}
					engagedValue={(props.row.original as StudyProgressObject).status_engaged}
				/>
			);
		},
	}),
	columnHelper.accessor('completed_screening_and_consent', {
		header: () => 'Completed Pre-screening Questionnaire',
	}),
	columnHelper.accessor('cancelled_not_eligible', {
		header: () => 'Found not eligible on pre-screening questionnaire',
	}),
	columnHelper.accessor('cancelled_not_eligible_now', {
		header: () => 'Found not currently eligible',
	}),
	columnHelper.accessor('status_consented', {
		header: () => 'Consent for nurse contact',
	}),
	columnHelper.accessor(
		(row) => `${row.status_screened + row.cancelled_manual_screening_failed}`,
		{
			id: 'nurse_screened',
			header: () => 'Screened by nurse',
		}
	),
	columnHelper.accessor('cancelled_manual_screening_failed', {
		header: () => 'Not eligible from nurse screening',
	}),
	columnHelper.accessor('status_screened', {
		header: () => 'Referred to trial site',
	}),
	columnHelper.accessor('status_randomised', {
		header: () => 'Randomised',
	}),
];

export type Columns = typeof columns;
