import clsx from 'clsx';
import { ChevronRight } from 'mdi-material-ui';
import { Link } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

export const Breadcrumbs = () => {
	const routes = [
		{ path: '/patient-contact/', breadcrumb: 'Patient Contact' },
		{ path: '/patient-contact/add-template', breadcrumb: 'Add Template' },
		{ path: '/patient-contact/send-letter', breadcrumb: 'Send Letter' },
		{ path: '/patient-contact/send-letter/preview', breadcrumb: 'Preview' },
		{
			path: '/patient-contact/mailings/:mailingListId',
			breadcrumb: 'Mailing Patient List',
		},
		{ path: '/patient-contact/create-mailing-list', breadcrumb: 'Create Mailing List' },
		{
			path: '/patient-contact/mailing-list-patients/:mailingListId',
			breadcrumb: 'Patient List',
		},
		{
			path: '/patient-contact/mailing-list-patients/:mailingListId/edit',
			breadcrumb: 'Edit Mailing List',
		},
		{ path: '/patient-engagement/', breadcrumb: 'Patient Engagement' },
		{ path: '/patient-engagement/:patient_identifier', breadcrumb: 'Patient Details' },
		{
			path: '/patient-engagement/:patient_identifier/edit',
			breadcrumb: 'Edit Patient Details',
		},
		{ path: '/patient-screening/', breadcrumb: 'Patient Screening' },
		{ path: '/patient-screening/:patient_identifier', breadcrumb: 'Patient Details' },
		{ path: '/patient-screening/:patient_identifier/edit', breadcrumb: 'Edit Patient Details' },
		{
			path: '/patient-screening/:patient_identifier/complete-screening',
			breadcrumb: 'Completing manual screening process',
		},
		{
			path: '/patient-screening/:patient_identifier/survey-assignment/:assignment_identifier',
			breadcrumb: 'Survey',
		},
		{
			path: '/trial-sites/',
			breadcrumb: 'Trial Sites',
		},
		{
			path: '/trial-sites/:patient_identifier',
			breadcrumb: 'Patient Details',
		},
		{
			path: '/trial-sites/:patient_identifier/complete-testing',
			breadcrumb: 'Complete Testing',
		},
		{
			path: '/trial-sites/:patient_identifier/survey-assignment/:assignment_identifier',
			breadcrumb: 'Survey',
		},
		{
			path: '/reporting/',
			breadcrumb: 'Reporting',
		},
		{
			path: '/reporting/study-progress',
			breadcrumb: 'Study progress',
		},
		{
			path: '/reporting/study-patient-status',
			breadcrumb: 'Patient status',
		},
		{
			path: '/reporting/ab-testing-metrics',
			breadcrumb: 'A/B Testing Metrics',
		},
		{
			path: '/reporting/dashboard',
			breadcrumb: 'Dashboard',
		},
		{ path: '/patient-approval/', breadcrumb: 'Patient Approval' },
		{ path: '/find/', breadcrumb: 'Find' },
		{ path: '/health-care-institution/', breadcrumb: 'Health care institution list' },
		{ path: '/health-care-institution/:institution_id', breadcrumb: 'HCI details' },
		{ path: '/health-care-institution/add', breadcrumb: 'Add new HCI' },
		{ path: '/health-care-institution/:institution_id/edit', breadcrumb: 'Edit HCI' },
		{ path: '/health-care-institution/:institution_id/project/add', breadcrumb: 'Add project' },
		{
			path: '/health-care-institution/:institution_id/project/:project_id/edit',
			breadcrumb: 'Manage project participation',
		},
		{ path: '/clinical-admin/', breadcrumb: 'Clinical Admin' },
		{ path: '/clinical-admin/:patient_identifier', breadcrumb: 'Patient Details' },
		{
			path: '/clinical-admin/:patient_identifier/complete-screening',
			breadcrumb: 'Completing manual screening process',
		},
		{
			path: '/clinical-admin/:patient_identifier/survey-assignment/:assignment_identifier',
			breadcrumb: 'Survey',
		},
		{ path: '/patient-lists/', breadcrumb: 'Patient Lists' },
		{ path: '/patient-lists/patients/:patient_identifier', breadcrumb: 'Patient Details' },
		{
			path: '/patient-lists/patients/:patient_identifier/edit',
			breadcrumb: 'Edit Patient Details',
		},
		{ path: '/patient-lists/lists/create-list', breadcrumb: 'Create list' },
		{ path: '/patient-lists/lists/:list_identifier', breadcrumb: 'List Details' },
		{ path: '/patient-lists/lists/:list_identifier/send-survey', breadcrumb: 'Send survey' },
	];

	const breadcrumbs = useBreadcrumbs(routes, { disableDefaults: true });

	return (
		<div className='flex flex-row mb-4 mx-8' data-test-id='breadcrumbs'>
			{breadcrumbs.map(({ match, breadcrumb }, index: number) => (
				<Link
					className={clsx(
						'flex flex-row items-center text-sm',
						index === breadcrumbs.length - 1
							? 'text-primaryBrand'
							: 'text-secondaryTextDark'
					)}
					key={match.pathname}
					to={match.pathname}
				>
					{breadcrumb}
					<ChevronRight
						className={clsx(
							'mx-2 md:mx-4',
							index === breadcrumbs.length - 1
								? 'text-primaryBrand'
								: 'text-secondaryTextDark'
						)}
					/>
				</Link>
			))}
		</div>
	);
};
