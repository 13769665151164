export const surveyIsEditable = (patientStatus: number | undefined, surveyType: string) => {
	/* 
    A survey can be edited if:
    - patient status is any status from the Patient screening page (e.g. Screening Required, 
		Screening passed, Screening failed, Rescreening, Consent delegated, Randomised)
    - survey type is 'Manual screening'
    */
	const surveyIsManualScreening = surveyType === 'Manual Screening';
	const allowedStatusArr = [530, 550, 580, 600, 610];
	// only need to check the arr if survey type is not valid
	const patientStatusAllowsEditing =
		patientStatus && !surveyIsManualScreening
			? allowedStatusArr.includes(patientStatus)
			: false;
	return patientStatusAllowsEditing || surveyIsManualScreening;
};
