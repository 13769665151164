import { FilterValuesObj } from '..';

type FilterNameObj = {
	id: string;
	name: string;
};

type FiltersArrObj = {
	[key: string]:
		| string
		| string[]
		| number
		| number[]
		| ((filterValue: string) => string | boolean)
		| undefined;
	formatting?: (filterValue: string | boolean) => string;
};

const formatParam = (filterValue: string | boolean | FilterValuesObj) => {
	let filterString;
	// check if the filterValue is an array and create a new array formatObjectValues by mapping through the fiterValue array
	// If an item in the array is an object, the function extracts its id property and pushes it into the new array. If it's not an object, it just pushes the item into the new array as is.
	if (Array.isArray(filterValue)) {
		const formatObjectValues = filterValue.map((item) => {
			if (typeof item === 'object') {
				return (item as FilterNameObj).id;
			}
			return item;
		});
		// joins the values in formatObjectValues into a string separated by commas and assigns the resulting string to filterString.
		filterString = formatObjectValues.join();
	} else {
		// if the filterValues variable is not an array, it assigns its value to filterString.
		filterString = filterValue;
	}
	return filterString;
};

export const buildFilterURL = (filtersArr: FiltersArrObj[]) => {
	// this function takes an array of filter objects and constructs the URL parameters based on each filter in the array.
	let filtersURL = '';
	filtersArr.forEach((filter) => {
		if (filter) {
			const filterArr = Object.entries(filter);
			if (filterArr && filterArr.length) {
				const [key, val] = filterArr[0];
				// default formatting function
				let formattingFunction = formatParam as (filterValue: string | boolean) => string;
				if (filterArr.length > 1) {
					const [optionsKey, filterFormattingFunction] = filterArr[1];
					if (
						optionsKey === 'formatting' &&
						typeof filterFormattingFunction === 'function'
					)
						// filter contains custom formatting function so use this instead
						formattingFunction = filterFormattingFunction as (
							filterValue: string | boolean
						) => string;
				}
				// append parameter to URL
				filtersURL += `&${key}=${val ? formattingFunction(val as string | boolean) : ''}`;
			}
		}
	});
	return filtersURL;
};

export const getPaginationQuery = (
	page: number | undefined,
	pageSize: string | number | undefined,
	getAll?: boolean
) => {
	return getAll ? `&all=true` : `&page=${page}&page_size=${pageSize}`;
};

export const valueHasFilterApplied = (value: string | string[] | boolean | FilterValuesObj) => {
	// check if the given value actually has a value
	return (value as string | string[])?.length > 0 || (value as boolean) === true;
};

export const getActiveFilters = (filterValues: FilterValuesObj, parseFilterField: string[]) => {
	// returns an object of filters that only have a value
	const activeFilters = { ...filterValues };
	// parse any required fields first as some values can be stored as a JSON string
	if (parseFilterField?.length) {
		parseFilterField.forEach((filterName: string) => {
			const thisFilter = activeFilters[filterName];
			if (thisFilter) {
				activeFilters[filterName] = Array.isArray(thisFilter)
					? thisFilter.map((f) => JSON.parse(f))
					: typeof thisFilter === 'string'
					? JSON.parse(thisFilter as string)
					: thisFilter;
			}
		});
	}
	return Object.fromEntries(
		Object.entries(activeFilters).filter(([, value]) => valueHasFilterApplied(value))
	);
};

export const isFiltersApplied = (filterValues: FilterValuesObj) => {
	return Object.values(filterValues).filter((elm) => valueHasFilterApplied(elm)).length > 0;
};
