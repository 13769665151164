import Check from 'mdi-material-ui/Check';
import Close from 'mdi-material-ui/Close';

import { AUTH } from '../consts';

export const PasswordChecker: React.FC<{ password: string }> = ({ password }) => {
	const iconSwitch = (match: boolean, name: string) => {
		return match ? (
			<Check className='w-6 h-6 mr-2 text-success' data-test-id={`${name}-Check`} />
		) : (
			<Close className='w-6 h-6 mr-2 text-error' data-test-id={`${name}-Cross`} />
		);
	};

	return (
		<>
			<ul className='mt-4'>
				<li className='flex text-secondaryTextDark'>
					{iconSwitch(/(?=.*[a-z])(?=.*[A-Z])/.test(password), 'LetterValIcon')}{' '}
					{AUTH.COMPONENTS.PASSWORD_CHECKER.LETTER_MATCH}{' '}
				</li>
				<li>
					<ul className='mt-2 flex flex-col justify-end items-center text-secondaryTextDark mb-2'>
						<li className='flex flex-row'>
							{AUTH.COMPONENTS.PASSWORD_CHECKER.UPPERCASE}
						</li>
						<li className='flex flex-row'>
							{AUTH.COMPONENTS.PASSWORD_CHECKER.LOWER_CASE}
						</li>
					</ul>
				</li>

				<li className='flex text-secondaryTextDark'>
					{iconSwitch(/\d/.test(password), 'NumValIcon')}
					{AUTH.COMPONENTS.PASSWORD_CHECKER.NUMBER}
				</li>
				<li className='flex text-secondaryTextDark'>
					{iconSwitch(/[!@£$^&%#*+_-]/.test(password), 'SymValIcon')}{' '}
					{AUTH.COMPONENTS.PASSWORD_CHECKER.SYMBOLS}
				</li>
				<li className='flex text-secondaryTextDark'>
					{iconSwitch(password.length >= 10, 'LenValIcon')}{' '}
					{AUTH.COMPONENTS.PASSWORD_CHECKER.MINIMUM_CHARACTERS}
				</li>
			</ul>
		</>
	);
};
