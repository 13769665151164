import { API_METHODS } from '@/globalConsts';
import { baseApi } from '@/lib/rtkQuery/baseApi';

import { TRIAL_SITES } from '../consts';
import {
	FetchTrialSitesPatientResponse,
	TrialSitesPatientRequest,
	saveTrialSiteScreeningResultRequest,
	commitResultResponse,
} from '../types';

export const trialSitesApi = baseApi
	.enhanceEndpoints({
		addTagTypes: ['Patient_List', 'notes_log'],
	})
	.injectEndpoints({
		endpoints: (build) => ({
			fetchTrialSitesQueryPatients: build.query<
				FetchTrialSitesPatientResponse,
				TrialSitesPatientRequest
			>({
				query: ({
					page,
					page_size,
					ordering,
					study_id,
					search_value,
					study_site_id,
					status,
					cancelled,
				}) => {
					const encoded_search_value = encodeURIComponent(
						search_value ? search_value : ''
					);
					const status_query = status ? `status=${status}` : `status=`;
					const cancelled_query = cancelled ? `cancelled=${cancelled}` : `cancelled=`;
					return {
						url: `${TRIAL_SITES.APIS.PATIENTS_LIST}?page=${page}&page_size=${page_size}&study_id=${study_id}&study_site_id=${study_site_id}&search=${encoded_search_value}&ordering=${ordering}&${status_query}&${cancelled_query}`,
						method: API_METHODS.GET,
					};
				},
				transformResponse: (response: FetchTrialSitesPatientResponse) => {
					return Array.isArray(response)
						? { results: response, total: response.length }
						: response;
				},
				providesTags: ['Patient_List'],
			}),
			saveTrialSiteScreeningResult: build.mutation<void, saveTrialSiteScreeningResultRequest>(
				{
					query: ({ patientId, randomising_status }) => {
						return {
							url: `${TRIAL_SITES.APIS.PATIENTS_LIST}${patientId}${TRIAL_SITES.APIS.TRAILING_SLASH}`,
							method: API_METHODS.PATCH,
							body: {
								randomising_status,
							},
						};
					},
				}
			),
			commitTrialSiteScreeningResult: build.mutation<commitResultResponse, string>({
				query: (patientId) => {
					return {
						url: `${TRIAL_SITES.APIS.PATIENTS_LIST}${patientId}${TRIAL_SITES.APIS.COMMIT}`,
						method: API_METHODS.PATCH,
					};
				},
				invalidatesTags: ['Patient_List'],
			}),
			createTrialSiteNote: build.mutation({
				query: ({ patient_id, text }) => {
					return {
						url: `${TRIAL_SITES.APIS.PATIENTS_LIST}${patient_id}${TRIAL_SITES.APIS.NOTES}`,
						method: API_METHODS.POST,
						body: { note: text },
					};
				},
				invalidatesTags: ['Patient_List', 'notes_log'],
			}),
			fetchPredefinedNotes: build.query({
				query: () => {
					return {
						url: TRIAL_SITES.APIS.FETCH_PREDEFINED_NOTE,
						method: API_METHODS.GET,
					};
				},
			}),
		}),
	});

export const {
	useFetchTrialSitesQueryPatientsQuery,
	useSaveTrialSiteScreeningResultMutation,
	useCommitTrialSiteScreeningResultMutation,
	useCreateTrialSiteNoteMutation,
	useFetchPredefinedNotesQuery,
} = trialSitesApi;
