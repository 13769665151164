import clsx from 'clsx';

import { ApplicationBar, Logo } from '@/components/Elements';

import { UserMenu } from '../UserMenu';

export type TopBarProps = {
	className?: string;
	isCollapsed: boolean;
};

export const TopBar = (props: TopBarProps) => {
	const { className, isCollapsed } = props;

	return (
		<ApplicationBar
			className={clsx(
				isCollapsed
					? '!w-[calc(100%-240px)] transition-width delay-100 duration-300 ease-in-out'
					: '!w-[calc(100%-64px)] transition-width delay-100 duration-300 ease-in-out',
				className
			)}
		>
			<Logo />
			<UserMenu />
		</ApplicationBar>
	);
};
