import clsx from 'clsx';
import React from 'react';

import { DialogsContainer } from '@/components/Dialogs';
import { Drawer } from '@/components/Elements/Drawer';
import { TopBar } from '@/components/Layout';
import { menuConfig } from '@/config/menuConfig';
import { useDisclosure } from '@/hooks/useDisclosure';

export interface MainLayoutProps {
	children: React.ReactNode;
}

export const MainLayout = (props: MainLayoutProps) => {
	const { children } = props;
	const { close, isOpen, open } = useDisclosure();

	return (
		<main
			className={clsx(
				'pt-20 outer min-w-[100vw]',
				isOpen
					? 'pl-[240px] transition-width delay-100 duration-300 ease-in-out'
					: 'pl-[64px] transition-width delay-100 duration-300 ease-in-out'
			)}
		>
			<TopBar isCollapsed={isOpen} />
			<Drawer
				config={menuConfig}
				close={close}
				isOpen={isOpen}
				open={open}
				className='fixed top-0 left-0'
			/>
			{children}
			<DialogsContainer />
		</main>
	);
};
