import { createColumnHelper } from '@tanstack/react-table';

import { DataTableObject } from '@/components/PatientDataTable';
import { formatDate } from '@/utils';

import { AllPatientListObject } from '../types';

const columnHelper = createColumnHelper<DataTableObject>();

export const allListsColumns = [
	columnHelper.accessor('name', {
		header: () => 'Name',
		enableSorting: true,
	}),
	columnHelper.accessor('patient_count', {
		header: () => 'No. patients',
		enableSorting: true,
	}),
	columnHelper.accessor('created_at', {
		header: () => 'Created date',
		enableSorting: true,
		cell: (props) => {
			return formatDate(props.getValue() as string);
		},
	}),
	columnHelper.accessor('created_by', {
		enableSorting: true,
		header: () => 'Created by',
	}),
];

export const allListRowProps = {
	rowSelect: true,
	onRowClickTarget: '/patient-lists/lists/',
	getRowId: (row: AllPatientListObject) => `${row.id}`,
	onClickClearStateValues: { tabValue: 0 },
};
