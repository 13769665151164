import { EditPatientFormValues } from '@/components';
import { CancelledStatus, isUs, phoneNumberPrefix, Status } from '@/globalConsts';

import { EditPatientRequestResponse } from '../types';

export const CheckScreeningRequiredStatus = (
	status: Status,
	status_label: string,
	cancelled: CancelledStatus,
	cancelled_label: string
) => {
	switch (status) {
		case Status.Consented:
			if (cancelled === CancelledStatus.NotCancelled) {
				return 'Screening Required';
			}
			break;
		case Status.ReScreening:
			if (cancelled === CancelledStatus.NotCancelled) {
				return 'Re-Screening Required';
			}
			break;
		case Status.Screened:
			if (cancelled === CancelledStatus.NotCancelled) {
				return 'Screening Passed';
			}
			break;
		case Status.ConsentDelegated:
			return 'Consent Delegated';
	}

	switch (cancelled) {
		case CancelledStatus.ScreeningNotEligible:
			return 'Screening Failed';
		case CancelledStatus.NotCancelled:
			break;
		default:
			return cancelled_label;
	}
	return status_label;
};

export const formatPhoneNumberDisplay = (phoneNumber: string | undefined) => {
	// Format phone numbers for display, remove any country code prefixs,
	//add a leading 0 if a UK number or format as (XXX) XXX-XXXX if a US number
	if (phoneNumber && phoneNumber.length >= 1) {
		const trimmedPhoneNumber = phoneNumber.slice(phoneNumberPrefix.length);
		if (isUs) {
			return `(${trimmedPhoneNumber.slice(0, 3)}) ${trimmedPhoneNumber.slice(
				3,
				6
			)}-${trimmedPhoneNumber.slice(6, 10)}`;
		} else {
			return '0' + trimmedPhoneNumber;
		}
	}
};

export const formatPatientDetailValues = (valuesObj: EditPatientFormValues) => {
	// strip out any non-numeric character
	let mobileNumber = valuesObj.contact_mobile_number.replace(/\D/g, '');
	let homeNumber = valuesObj.contact_home_number.replace(/\D/g, '');

	// Check to see we have a mobile number, if we do and it's a UK number
	// remove the leading 0 and add the country code prefix, if it's a US number
	//  just add the country code prefix
	// if there is no mobile number just return the empty string
	mobileNumber.length >= 1
		? (mobileNumber = phoneNumberPrefix + mobileNumber.slice(isUs ? 0 : 1))
		: mobileNumber;

	// Check to see we have a home number, if we do and it's a UK number
	// remove the leading 0 and add the country code prefix, if it's a US number
	//  just add the country code prefix
	// if there is no home number just return the empty string
	homeNumber.length >= 1
		? (homeNumber = phoneNumberPrefix + homeNumber.slice(isUs ? 0 : 1))
		: homeNumber;

	const returnObj: EditPatientRequestResponse = {
		contact_email: valuesObj.contact_email,
		contact_mobile_number: mobileNumber,
		contact_home_number: homeNumber,
		needs_assistance: valuesObj.needs_assistance === 'true',
	};

	// check to see if user is being opted out. Value should only be sent if true.
	if (valuesObj.opt_out) {
		returnObj['opt_out'] = true;
	}

	// check to see if user is being marked as deceased. Value should only be sent if true.
	if (valuesObj.deceased) {
		returnObj['deceased'] = true;
	}

	return returnObj;
};

export const capitaliseFirstLetter = (str: string | undefined) => {
	return str && str.charAt(0).toUpperCase() + str.slice(1);
};
