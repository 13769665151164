import clsx from 'clsx';
import { createContext, useState } from 'react';

import { Breadcrumbs, SelectorContainer } from '@/components';
import { trackEvent } from '@/lib/analytics';

type TableViewProps = {
	children: React.ReactElement;
	selectorType: 'study' | 'Trial Site' | 'Study and survey';
};

type StateType = boolean;

type SelectorContextType = {
	isExpanded: StateType;
	selectorType: 'study' | 'Trial Site' | 'Study and survey';
};

export const SelectorContext = createContext<SelectorContextType>({} as SelectorContextType);

export const TableView = (props: TableViewProps) => {
	const { children, selectorType } = props;
	const [isExpanded, setIsExpanded] = useState<StateType>(true);

	const toggleSelector = () => {
		setIsExpanded(!isExpanded);
		const options = { isExpanded: !isExpanded };
		trackEvent('Selector display toggled', options);
	};

	const value = { isExpanded, selectorType };

	return (
		<SelectorContext.Provider value={value}>
			<div className='flex flex-row justify-start items-start w-full'>
				<SelectorContainer type={selectorType} handleClick={toggleSelector} />
				<div
					className={clsx(
						'w-[calc(100vw-64px)] transition-padding ease-linear pt-8 pb-4',
						isExpanded ? 'pl-60' : 'pl-6'
					)}
				>
					<Breadcrumbs />
					{children}
				</div>
			</div>
		</SelectorContext.Provider>
	);
};
