import { createColumnHelper } from '@tanstack/react-table';

import { PatientStatusObject } from '../types';

const columnHelper = createColumnHelper<PatientStatusObject>();

export const patientStatusColumns = [
	columnHelper.accessor('cohort_subgroup_name', {
		header: () => 'Cohort',
	}),
	columnHelper.accessor('provider_name', {
		header: () => 'Organisaton',
	}),
	columnHelper.accessor('partnership_name', {
		header: () => 'Partnership',
	}),
	columnHelper.accessor('new_candidates', {
		header: () => 'Candidates',
	}),
	columnHelper.accessor('approved', {
		header: () => 'Approved',
	}),
	columnHelper.accessor('provider_denied', {
		header: () => 'Provider Denied',
	}),
	columnHelper.accessor('engaged', {
		header: () => 'Engaged',
	}),
	columnHelper.accessor('consented', {
		header: () => 'Consented',
	}),
	columnHelper.accessor('not_consented', {
		header: () => 'Not Consented',
	}),
	columnHelper.accessor('not_yet_eligible', {
		header: () => 'Not Yet Consented',
	}),
	columnHelper.accessor('screened_manually', {
		header: () => 'Nurse Screened',
	}),
	columnHelper.accessor('screened_manually_excluded', {
		header: () => 'Nurse Excluded',
	}),
	columnHelper.accessor('randomised', {
		header: () => 'Trial Site Randomised',
	}),
	columnHelper.accessor('studysite_excluded', {
		header: () => 'Trial Site Excluded',
	}),
];

export const patientStatusPatientListDefaultTab = 0;
