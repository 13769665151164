import { Typography } from '@@/components/Elements';
import clsx from 'clsx';
import { useState } from 'react';

export type MenuItemProps = {
	children: string;
	className?: string;
	icon: string;
	iconActive: string;
	iconHover: string;
	isActive?: boolean;
	isExpanded: boolean;
	onClick?: () => void;
};
export const MenuItem = (props: MenuItemProps) => {
	const { children, className, icon, iconActive, isActive, iconHover, isExpanded, onClick } =
		props;
	const [isHover, setIsHover] = useState(false);

	const onMouseEnter = () => {
		setIsHover(true);
	};

	const onMouseLeave = () => {
		setIsHover(false);
	};

	const renderMenuIcon = () => {
		const iconSrc = isHover ? iconHover : isActive ? iconActive : icon;
		return <img className='w-6' alt={children} title={children} src={iconSrc} />;
	};

	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
		<div
			className={clsx(
				'flex flex-row items-center h-[45px] w-full bg-primaryBackground hover:bg-highlight rounded-md px-[10px] mt-2 mb-4 transition-width ease-in-out delay-100 duration-300',
				className
			)}
			onClick={onClick}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			{renderMenuIcon()}
			<Typography
				className={clsx(
					'pt-1 ml-2',
					'transition-visibility ease-linear delay-300 duration-300',
					isExpanded
						? 'w-auto opacity-100 visible '
						: 'w-0 overflow-x-hidden opacity-0 invisible'
				)}
			>
				{children}
			</Typography>
		</div>
	);
};
