import {
	saveStudyId,
	saveTrialSiteId,
	selectStudyId,
	selectTrialSiteId,
} from '@/components/PatientDataTable';

import { useFetchStudiesQuery, useFetchTrialSitesQuery } from '../api/SelectorApi';

export const FetchSelectorType = (type: string) => {
	switch (type) {
		case 'Study':
			return {
				fetch: useFetchStudiesQuery,
				title: 'Study',
				saveId: saveStudyId,
				selectorId: selectStudyId,
				parentItemClickEnabled: true,
			};
		case 'Trial Site':
			return {
				fetch: useFetchTrialSitesQuery,
				title: 'Trial Site',
				saveId: saveTrialSiteId,
				selectorId: selectTrialSiteId,
				parentItemClickEnabled: false,
			};
		default:
			return {
				fetch: useFetchStudiesQuery,
				title: 'Study',
				saveId: saveStudyId,
				selectorId: selectStudyId,
				parentItemClickEnabled: true,
			};
	}
};
