import { createSlice } from '@reduxjs/toolkit';
import { VisibilityState } from '@tanstack/react-table';

import { trackEvent } from '@/lib/analytics';
import { RootState } from '@/stores/store';
import storage from '@/utils/storage';

import { FilterValuesObj } from '../types';

const dataTableState = JSON.parse(storage.getDataTableState() as string);

export type DataTableState = {
	batchRowSelection?: { [key: string]: boolean };
	columnVisibility?: VisibilityState;
	filterValues: object | FilterValuesObj;
	filterWindowState: boolean;
	ordering?: string;
	pageSize: number | string;
	pageNumber?: number;
	searchValue?: string;
	searchSelectorValue?: string;
	tabValue?: number;
	orderType?: string;
	orderedColumn?: string;
	studyId: string;
	trialSiteId?: string;
	tableScrollPosition?: number;
	rowsTotal?: number;
	patientId?: string;
	patientStatus?: number;
	refreshMailingListPatients?: boolean;
	selectedRowData: Record<string, unknown>;
	surveyId?: string;
	surveyName?: string;
};

const resetStateValues: DataTableState = {
	batchRowSelection: {},
	columnVisibility: {},
	filterValues: {},
	filterWindowState: false,
	ordering: '',
	pageSize: 10,
	pageNumber: 1,
	searchValue: '',
	searchSelectorValue: '',
	orderType: 'default',
	orderedColumn: '',
	studyId: '',
	trialSiteId: '',
	tableScrollPosition: 0,
	rowsTotal: 0,
	refreshMailingListPatients: false,
	selectedRowData: {},
	surveyId: '',
	surveyName: 'Survey List',
};
const initialState: DataTableState = {
	...resetStateValues,
	...dataTableState,
};

const trackFiltersApplied = (filterValues: FilterValuesObj) => {
	const hasFiltersApplied =
		Object.values(filterValues).filter((elm) => (elm as string)?.length > 0 || elm === true)
			.length > 0;
	if (hasFiltersApplied) trackEvent('Data table filters applied');
};

export const DataTableSlice = createSlice({
	name: 'dataTable',
	initialState,
	reducers: {
		reset: () => {
			storage.clearDataTableState();
			return resetStateValues;
		},
		resetFilterValues: (state) => {
			state.filterValues = {};
			trackEvent('Data table filters reset');
		},
		resetBatchSelection: (state) => {
			state.batchRowSelection = {};
		},
		resetState: () => {
			storage.clearDataTableState();
			return { ...resetStateValues };
		},
		resetDataTableStateValues: (state, action) => {
			const {
				pageSize = 10,
				pageNumber = 1,
				batchRowSelection = {},
				columnVisibility = {},
				ordering = '',
				searchValue = '',
				searchSelectorValue = '',
				tableScrollPosition = 0,
				rowsTotal = 0,
				tabValue = 1,
				orderType = 'default',
				orderedColumn = '',
				filterValues = {},
			} = action.payload || {};
			state.pageSize = pageSize;
			state.pageNumber = pageNumber;
			state.batchRowSelection = batchRowSelection;
			state.columnVisibility = columnVisibility;
			state.ordering = ordering;
			state.searchValue = searchValue;
			state.searchSelectorValue = searchSelectorValue;
			state.tableScrollPosition = tableScrollPosition;
			state.rowsTotal = rowsTotal;
			state.tabValue = tabValue;
			state.orderType = orderType;
			state.orderedColumn = orderedColumn;
			state.filterValues = filterValues;
		},
		saveBatchRowSelection: (state, action) => {
			const { batchRowSelection } = action.payload;
			state.batchRowSelection = batchRowSelection;
		},
		saveColumnVisibility: (state, action) => {
			const { columnVisibility } = action.payload;
			state.columnVisibility = columnVisibility;
		},
		saveFilterValues: (state, action) => {
			const { filterValues } = action.payload;
			state.filterValues = filterValues;
			state.pageNumber = 1;
			trackFiltersApplied(filterValues);
		},
		setFilterWindowState: (state, action) => {
			const { filterWindowState } = action.payload;
			state.filterWindowState = filterWindowState;
		},
		saveOrdering: (state, action) => {
			const { ordering } = action.payload;
			state.ordering = ordering;
		},
		savePageSize: (state, action) => {
			const { pageSize } = action.payload;
			state.pageSize = pageSize;
			state.pageNumber = 1;
		},
		savePageNumber: (state, action) => {
			const { pageNumber } = action.payload;
			state.pageNumber = pageNumber;
		},
		saveSearchValue: (state, action) => {
			const { searchValue } = action.payload;
			state.searchValue = searchValue;
			state.pageNumber = 1;
		},
		saveSearchStudyValue: (state, action) => {
			const { searchValue } = action.payload;
			state.searchSelectorValue = searchValue;
			state.pageNumber = 1;
		},
		saveSelectedRowData: (state, { payload }) => {
			const { rowData } = payload;
			state.selectedRowData = rowData;
		},
		saveSelectedTab: (state, action) => {
			const { tabValue } = action.payload;
			state.tabValue = tabValue;
			state.pageNumber = 1;
		},
		saveOrderType: (state, action) => {
			const { orderType } = action.payload;
			state.orderType = orderType;
		},
		saveOrderedColumn: (state, action) => {
			const { orderedColumn } = action.payload;
			state.orderedColumn = orderedColumn;
		},
		saveStudyId: (state, action) => {
			const { id } = action.payload;
			return {
				...resetStateValues,
				studyId: id,
				trialSiteId: state.trialSiteId,
				searchSelectorValue: state.searchSelectorValue,
			};
		},
		saveTrialSiteId: (state, action) => {
			const { id } = action.payload;
			return {
				...resetStateValues,
				trialSiteId: id,
				studyId: state.studyId,
				searchSelectorValue: state.searchSelectorValue,
			};
		},
		saveTableScrollPosition: (state, action) => {
			const { tableScrollPosition } = action.payload;
			state.tableScrollPosition = tableScrollPosition;
		},
		saveRowsTotal: (state, action) => {
			const { rowsTotal } = action.payload;
			state.rowsTotal = rowsTotal;
		},
		savePatientId: (state, action) => {
			const { patientId } = action.payload;
			state.patientId = patientId;
		},
		savePatientStatus: (state, action) => {
			const { patientStatus } = action.payload;
			state.patientStatus = patientStatus;
		},
		refreshMailingListPatientsTable: (state, action) => {
			const refresh = action.payload;
			state.refreshMailingListPatients = refresh;
		},
		saveSurveyId: (state, action) => {
			const { surveyId } = action.payload;
			state.surveyId = surveyId;
		},
		saveSurveyName: (state, action) => {
			const { surveyName } = action.payload;
			state.surveyName = surveyName;
		},
	},
});

export const {
	reset,
	resetFilterValues,
	resetBatchSelection,
	resetState,
	resetDataTableStateValues,
	saveBatchRowSelection,
	saveColumnVisibility,
	saveFilterValues,
	setFilterWindowState,
	saveOrdering,
	savePageNumber,
	savePageSize,
	saveSearchValue,
	saveSearchStudyValue,
	saveSelectedTab,
	saveOrderedColumn,
	saveOrderType,
	saveStudyId,
	saveTrialSiteId,
	saveTableScrollPosition,
	saveRowsTotal,
	savePatientId,
	savePatientStatus,
	refreshMailingListPatientsTable,
	saveSelectedRowData,
	saveSurveyId,
	saveSurveyName,
} = DataTableSlice.actions;

export const selectBatchRowSelection = (state: RootState) => state.dataTable.batchRowSelection;
export const selectColumnVisibility = (state: RootState) => state.dataTable.columnVisibility;
export const selectFilterValues = (state: RootState) => state.dataTable.filterValues;
export const selectFilterWindowState = (state: RootState) => state.dataTable.filterWindowState;
export const selectOrdering = (state: RootState) => state.dataTable.ordering;
export const selectPageSize = (state: RootState) => state.dataTable.pageSize;
export const selectPageNumber = (state: RootState) => state.dataTable.pageNumber;
export const selectSearchValue = (state: RootState) => state.dataTable.searchValue;
export const selectSelectorSearchValue = (state: RootState) => state.dataTable.searchSelectorValue;
export const selectOrderedColumn = (state: RootState) => state.dataTable.orderedColumn;
export const selectOrderType = (state: RootState) => state.dataTable.orderType;
export const selectStudyId = (state: RootState) => state.dataTable.studyId;
export const selectTrialSiteId = (state: RootState) => state.dataTable.trialSiteId;
export const selectTabValue = (state: RootState) => state.dataTable.tabValue;
export const selectTableScrollPosition = (state: RootState) => state.dataTable.tableScrollPosition;
export const selectRowsTotal = (state: RootState) => state.dataTable.rowsTotal;
export const selectPatientId = (state: RootState) => state.dataTable.patientId;
export const selectPatientStatus = (state: RootState) => state.dataTable.patientStatus;
export const selectRefreshMailingListPatients = (state: RootState) =>
	state.dataTable.refreshMailingListPatients;
export const selectSelectedRowData = (state: RootState) => state.dataTable.selectedRowData;
export const selectSurveyId = (state: RootState) => state.dataTable.surveyId;
export const selectSurveyName = (state: RootState) => state.dataTable.surveyName;
