import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '@/stores/store';
import { FeatureFlagsResponseObject } from '@/types';

const initialState: FeatureFlagsResponseObject = {
	flags: {},
	samples: {},
	switches: {},
};

export const FeatureDefinitionsSlice = createSlice({
	name: 'featureDefinitions',
	initialState,
	reducers: {
		saveFeatureFlags: (state, { payload }) => {
			const { featureFlags } = payload;
			state.flags = featureFlags;
		},
		saveFeatureSamples: (state, { payload }) => {
			const { featureSamples } = payload;
			state.samples = featureSamples;
		},
		saveFeatureSwitches: (state, { payload }) => {
			const { featureSwitches } = payload;
			state.switches = featureSwitches;
		},
	},
});

export const { saveFeatureFlags, saveFeatureSamples, saveFeatureSwitches } =
	FeatureDefinitionsSlice.actions;

export const selectAvailableFlags = (state: RootState) => state.featureDefinitions.flags;
export const selectAvailableSamples = (state: RootState) => state.featureDefinitions.samples;
export const selectAvailableSwitches = (state: RootState) => state.featureDefinitions.switches;
