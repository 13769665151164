import { Button } from '@@/components/Elements';
import clsx from 'clsx';
import { FieldHookConfig, useField } from 'formik';
import EyeOffOutline from 'mdi-material-ui/EyeOffOutline';
import EyeOutline from 'mdi-material-ui/EyeOutline';
import { useState } from 'react';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type PasswordFieldProps = FieldWrapperPassThroughProps & {
	className?: string;
	placeholder?: string;
};

export const PasswordField = (props: PasswordFieldProps & FieldHookConfig<string>) => {
	const { label, name, placeholder, width, className } = props;
	const [field, meta] = useField(props);
	const { error, touched } = meta;

	const [showPassword, setShowPassword] = useState(false);

	const handleClick = () => {
		showPassword ? setShowPassword(false) : setShowPassword(true);
	};

	return (
		<>
			<FieldWrapper error={error} touched={touched} label={label} width={width}>
				<input
					type={showPassword ? 'text' : 'password'}
					id={name}
					placeholder={placeholder}
					{...field}
					className={clsx(
						'flex-1 min-w-0 block w-full h-11 p-3 text-base rounded border-primaryBorder placeholder:text-base placeholder:text-disabledText',
						error && touched
							? 'border-error placeholder:text-error focus:ring-error focus:border-error'
							: 'focus:ring-primaryBrand focus:border-primaryBrand',
						className
					)}
					aria-invalid='true'
					aria-describedby='email-error'
				/>

				<div className='absolute inset-y-0 right-1 flex items-center'>
					<Button variant='icon' size='icon' onClick={handleClick}>
						{showPassword ? (
							<EyeOffOutline className='w-6 h-6 text-primaryTextDark' />
						) : (
							<EyeOutline className='w-6 h-6 text-primaryTextDark' />
						)}
					</Button>
				</div>
			</FieldWrapper>
		</>
	);
};
