import clsx from 'clsx';
import InformationOutline from 'mdi-material-ui/InformationOutline';
import { useState } from 'react';

export type TooltipProps = {
	iconClassName?: string;
	tooltipClassName?: string;
	description: string;
	children: string | string[] | React.ReactElement | React.ReactElement[];
	position?: 'top' | 'right' | 'bottom' | 'left';
};

export const Tooltip = (props: TooltipProps) => {
	const { iconClassName, tooltipClassName, description, children, position = 'right' } = props;
	const [hideTooltip, setHideTooltip] = useState(true);
	const containerPosition = {
		top: 'bottom-[40px] right-[-16px] lg:bottom-[36px] lg:right-0 lg:translate-x-[50%]',
		right: 'top-[35px] right-[-16px] lg:top-[12px] lg:left-[45px] lg:right-auto lg:translate-y-[-50%]',
		bottom: 'top-[35px] right-[-16px] lg:top-[12px] lg:top-[32px] lg:right-0 lg:translate-x-[50%]',
		left: 'top-[35px] right-[-16px] lg:top-[12px] lg:right-[40px] lg:translate-y-[-50%]',
	};
	const pointerPosition = {
		top: 'rotate-[-45deg] right-[22px] bottom-[-6px] ml-[-18px] lg:bottom-[-6px] lg:rotate-[-45deg] lg:left-[50%] lg:ml-[-18px]',
		right: 'rotate-[132deg] right-[22px] top-[-6px] lg:rotate-45 lg:right-auto lg:left-[-6px] lg:top-[50%] lg:mt-[-6px]',
		bottom: 'rotate-[132deg] right-[22px] top-[-6px] lg:rotate-[132deg] lg:right-auto lg:top-[-5px] lg:left-[50%] lg:ml-[-18px]',
		left: 'rotate-[132deg] right-[22px] top-[-6px] lg:rotate-[226deg] lg:right-[-6px] lg:top-[50%] lg:mt-[-6px]',
	};

	return (
		<div className='relative max-w-md flex'>
			<button
				className={clsx('flex peer', iconClassName)}
				aria-describedby='tooltip-message'
				onClick={() => {
					setHideTooltip(!hideTooltip);
				}}
			>
				<span className='sr-only'>{description}</span>
				<InformationOutline aria-hidden='true' className='text-secondaryTextDark' />
			</button>
			<div
				className={`flex absolute z-50 peer-hover:!visible max-w-[80vw]
					${containerPosition[position]}
					${hideTooltip ? 'invisible' : ''}
				`}
			>
				<div
					tabIndex={-1}
					className={clsx(
						'block w-[450px] max-w-sm md:max-w-md bg-primaryBackground text-primaryTextDark p-4 border-2 border-secondaryBorder rounded-[4px] relative z-1',
						tooltipClassName
					)}
					id='tooltip-message'
					aria-hidden='true'
					role='tooltip'
				>
					{children}
				</div>
				<span
					className={`absolute w-[12px] h-[12px] bg-primaryBackground border-2 border-secondaryBorder border-t-0 border-r-0 z-0 ${pointerPosition[position]}
				`}
				></span>
			</div>
		</div>
	);
};
