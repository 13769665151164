import { Button } from '@@/components/Elements';
import { Dialog } from '@headlessui/react';
import { Close } from 'mdi-material-ui';
import { useDispatch } from 'react-redux';

import { confirmDialog, ReduxDialogProps } from './dialogsSlice';

interface ConfirmationDialogProps extends Omit<ReduxDialogProps, 'type'> {
	onDismiss: (id: string) => void;
}

export const ConfirmationDialog = (props: ConfirmationDialogProps) => {
	const { id, title, description, confirmButtonLabel, onDismiss } = props;
	const dispatch = useDispatch();

	const onConfirm = () => {
		dispatch(confirmDialog({ id }));
	};

	return (
		<Dialog.Panel className='transform overflow-hidden rounded-lg bg-primaryBackground border-2 border-secondaryBorder p-6 transition-all max-w-[560px]'>
			<div className='flex flex-row justify-between align-middle mb-2 pb-4 border-b border-secondaryBorder'>
				<Dialog.Title as='h4' data-test-id={`dialog-${id}`} className='text-lg'>
					{title}
				</Dialog.Title>
				<Button
					variant='icon'
					size='icon'
					className='px-0 py-0'
					onClick={() => onDismiss(id)}
				>
					<Close className='w-4 h-4' />
				</Button>
			</div>
			<Dialog.Description
				as='div'
				data-test-id={`dialog-description`}
				className='text-md mt-2 mb-4 pt-4 pb-6'
			>
				{description}
			</Dialog.Description>
			<div className='flex flex-row justify-between'>
				<Button variant='secondary' size='md' onClick={() => onDismiss(id)}>
					No, cancel
				</Button>
				<Button variant='primary' size='md' onClick={onConfirm}>
					{confirmButtonLabel}
				</Button>
			</div>
		</Dialog.Panel>
	);
};
