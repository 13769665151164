import React from 'react';

import { DialogContainer } from '../../Elements';

type TermsAndConditionsProps = {
	isOpen: boolean;
	title: string;
	close: () => void;
	children?: React.ReactNode | string;
};

export const TermsAndConditions: React.FC<TermsAndConditionsProps> = ({
	isOpen,
	title,
	close,
	children,
}) => {
	return (
		<DialogContainer close={close} className='w-1/2' isOpen={isOpen} title={title}>
			<>{children}</>
		</DialogContainer>
	);
};
