import { CreateContactLogRequest, CreateContactLogResponse } from '@/features/patientEngagement';
import { API_METHODS } from '@/globalConsts';
import { baseApi } from '@/lib/rtkQuery/baseApi';
import { PatientObject } from '@/types';

import { PATIENT_DETAILS } from '../consts';
import {
	FetchContactLogsRequest,
	FetchPatientNoteHistoryLogResponse,
	FetchPatientNotesHistoryRequest,
	PatientDetailsResponse,
} from '../types';
import { formatPatientDetailValues } from '../utils';

export const patientDetailsApi = baseApi
	.enhanceEndpoints({
		addTagTypes: ['contact_log', 'patient_details', 'notes_log'],
	})
	.injectEndpoints({
		endpoints: (build) => ({
			fetchPatientDetails: build.query<PatientObject, string>({
				query: (patient_identifier) => {
					return {
						url: `${PATIENT_DETAILS.APIS.PATIENTS}${patient_identifier}${PATIENT_DETAILS.APIS.TRAILING_SLASH}`,
						method: API_METHODS.GET,
					};
				},
				providesTags: ['patient_details'],
			}),
			fetchContactLog: build.query<PatientDetailsResponse, FetchContactLogsRequest>({
				query: ({ patient_identifier, ordering, page, page_size }) => {
					return {
						url: `${PATIENT_DETAILS.APIS.CONTACT_LOGS}?patient=${patient_identifier}&page=${page}&page_size=${page_size}&ordering=${ordering}`,
						method: API_METHODS.GET,
					};
				},
				providesTags: ['contact_log'],
			}),
			fetchNotesLog: build.query<PatientDetailsResponse, FetchPatientNotesHistoryRequest>({
				query: ({ patient_identifier }) => {
					return {
						url: `${PATIENT_DETAILS.APIS.PATIENT_NOTES}${patient_identifier}${PATIENT_DETAILS.APIS.NOTE}`,
						method: API_METHODS.GET,
					};
				},
				transformResponse: (response: FetchPatientNoteHistoryLogResponse[]) => {
					return { results: response, total: response.length };
				},
				providesTags: ['notes_log'],
			}),
			createContactLog: build.mutation<CreateContactLogResponse, CreateContactLogRequest>({
				query: (body) => {
					return {
						url: PATIENT_DETAILS.APIS.CONTACT_LOGS,
						method: API_METHODS.POST,
						body,
					};
				},
				invalidatesTags: ['contact_log', 'Patient_List'],
			}),
			updatePatientDetails: build.mutation({
				query: ({ values, patient_identifier }) => {
					return {
						url: `v1/patients/${patient_identifier}/`,
						method: 'PATCH',
						body: formatPatientDetailValues(values),
					};
				},
				invalidatesTags: ['patient_details'],
			}),
		}),
	});

export const {
	useCreateContactLogMutation,
	useFetchPatientDetailsQuery,
	useFetchContactLogQuery,
	useFetchNotesLogQuery,
	useUpdatePatientDetailsMutation,
	util,
} = patientDetailsApi;
