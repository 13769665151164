import { Helmet } from 'react-helmet-async';

import { defaultSiteLanguage } from '@/globalConsts';
import { AppProvider } from '@/providers/app';
import { AppRoutes } from '@/routes';

const App = () => {
	return (
		<AppProvider>
			<Helmet>
				<html lang={defaultSiteLanguage} />
			</Helmet>
			<AppRoutes />
		</AppProvider>
	);
};

export default App;
