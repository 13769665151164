import { baseApi } from '@/lib/rtkQuery/baseApi';

import {
	FetchStudyRequest,
	StudyResponse,
	StudiesData,
	TrialSiteResponse,
	studyListResponse,
	selectorObj,
} from '../types';

export const SelectorApi = baseApi.enhanceEndpoints({}).injectEndpoints({
	endpoints: (build) => ({
		fetchStudies: build.query<studyListResponse, FetchStudyRequest>({
			query: ({ search_value }) => {
				const encoded_search_value = encodeURIComponent(search_value ? search_value : '');
				return {
					url: `v1/study/?search=${encoded_search_value}&all=true`,
				};
			},
			transformResponse: (response: StudyResponse) => {
				const studiesResponse = Array.isArray(response)
					? { results: response, total: response.length }
					: response;

				const results: selectorObj[] = [];
				const parentStudies = studiesResponse.results.filter(
					(elm: StudiesData) => elm.parent_study_id === null
				);
				parentStudies.forEach((study: StudiesData) => {
					results.push({
						parentItem: { id: study.id, name: study.name },
						nestedItems: studiesResponse.results
							.filter(
								(subStudy: { parent_study_id: string }) =>
									subStudy.parent_study_id === study.id
							)
							.map((elm) => ({ id: elm.id, name: elm.name })),
					});
				});
				return { results, total: results.length };
			},
		}),
		fetchTrialSites: build.query<studyListResponse, { search_value?: string }>({
			query: ({ search_value }) => {
				const encoded_search_value = encodeURIComponent(search_value ? search_value : '');
				return {
					url: `v1/study-site/role/?search=${encoded_search_value}&all=true&role=admin`,
				};
			},
			transformResponse: (response: TrialSiteResponse[]) => {
				const TrialSiteResponse = response;
				const results: selectorObj[] = [];
				const studies = TrialSiteResponse.filter((elm, index, self) => {
					return index === self.findIndex((o) => o.study_id === elm.study_id);
				});
				studies.forEach((study) => {
					results.push({
						parentItem: { id: study.study_id, name: study.study_name },
						nestedItems: TrialSiteResponse.filter(
							(elm) => elm.study_id === study.study_id
						).map((elm) => ({
							id: elm.study_site_id,
							name: elm.study_site_name,
						})),
					});
				});
				return { results, total: results.length };
			},
		}),
	}),
});

export const { useFetchStudiesQuery, useFetchTrialSitesQuery } = SelectorApi;
