import { Button, Typography } from '@@/components/Elements';
import { Disclosure, Transition } from '@headlessui/react';
import {
	ChevronDownIcon,
	SearchIcon,
	ChevronRightIcon,
	ChevronLeftIcon,
} from '@heroicons/react/outline';
import clsx from 'clsx';
import { Field, Form, Formik } from 'formik';
import React, { FC, Key, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { SkeletonLoader } from '@/components/Elements';
import { SearchFormValues } from '@/components/Form/types';
import {
	savePageNumber,
	saveSearchStudyValue,
	saveStudyId,
	selectSelectorSearchValue,
} from '@/components/PatientDataTable';
import { SelectorContext } from '@/components/Reporting';
import { trackEvent } from '@/lib/analytics';
import { debounce } from '@/utils';

import { nestedItem, selectorObj } from '../types';
import { FetchSelectorType } from '../Utils';

type SelectorContainerProps = {
	handleClick: () => void;
	type: 'study' | 'Trial Site' | 'Study and survey';
};

export const SelectorContainer = (props: SelectorContainerProps) => {
	const { handleClick, type } = props;
	const { fetch, title, saveId, selectorId, parentItemClickEnabled } = FetchSelectorType(type);
	const dispatch = useDispatch();
	const savedId = useSelector(selectorId);
	const search_value = useSelector(selectSelectorSearchValue);
	const { isExpanded } = useContext(SelectorContext);

	const eventOptions = {
		selectorType: type,
	};

	const handleItemClick = (id: string, trialSiteStudyId?: string) => {
		dispatch(saveId({ id }));
		trialSiteStudyId && dispatch(saveStudyId({ id: trialSiteStudyId }));
		trackEvent('Selector option selected', eventOptions);
	};
	const { data, isLoading } = fetch({
		search_value,
	});

	const schema = Yup.object().shape({
		searchValue: Yup.string(),
	});
	const initialValues = {
		searchValue: search_value || '',
	};

	const saveSearchDebounced = debounce((s: string) => {
		saveSearch && saveSearch(s);
		trackEvent('Selector search used', eventOptions);
	});

	const saveSearch = (searchValue: string) => {
		dispatch(saveSearchStudyValue({ searchValue: searchValue }));
		dispatch(savePageNumber({ pageNumber: 1 }));
	};

	const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const searchValue = e.target.value;
		saveSearchDebounced(searchValue);
	};

	const handleSearchSubmit = (values: SearchFormValues) => {
		const searchValue = values.searchValue;
		saveSearch(searchValue);
	};

	const ChildListItem: FC<{
		item: nestedItem;
		parentItem?: nestedItem;
	}> = ({ item, parentItem }) => {
		return (
			<button
				type='button'
				className='block text-left'
				onClick={() =>
					handleItemClick(item.id, parentItemClickEnabled ? '' : parentItem?.id)
				}
			>
				<Typography
					variant='body'
					dataTestId={`study-selector-item-${item.name}`}
					className={clsx(
						'text-primaryTextDark cursor-pointer p-2 my-1 ',
						savedId === item.id ? 'font-bold bg-primaryBackground' : 'font-normal'
					)}
				>
					{item.name}
				</Typography>
			</button>
		);
	};

	const ParentItem: FC<{ item: nestedItem; nestedItems: nestedItem[] }> = ({
		item,
		nestedItems,
	}) => {
		return (
			<Disclosure defaultOpen={nestedItems.filter((elm) => elm.id === savedId).length > 0}>
				{({ open }) => (
					<>
						<Disclosure.Button
							className={clsx(
								'flex w-full justify-between my-1 text-left text-sm font-medium items-center',
								savedId === item.id ? '' : 'font-normal'
							)}
							data-test-id={`${item.name}`}
							onClick={() => parentItemClickEnabled && handleItemClick(item.id)}
						>
							<Typography
								variant='body'
								className={clsx(
									'text-primaryTextDark cursor-pointer p-2',
									savedId === item.id ? 'font-bold' : 'font-normal'
								)}
							>
								{item.name}
							</Typography>
							<ChevronDownIcon
								className={`${
									open ? 'rotate-180 transform' : ''
								} h-5 w-5 text-primaryBrand`}
							/>
						</Disclosure.Button>
						<Transition
							enter='transition duration-100 ease-out'
							enterFrom='transform scale-95 opacity-0'
							enterTo='transform scale-100 opacity-100'
							leave='transition duration-75 ease-out'
							leaveFrom='transform scale-100 opacity-100'
							leaveTo='transform scale-95 opacity-0'
						>
							<Disclosure.Panel className='mx-4'>
								{nestedItems.map((elm: nestedItem, i: Key) => (
									<ChildListItem
										item={elm}
										parentItem={item}
										key={i}
									></ChildListItem>
								))}
							</Disclosure.Panel>
						</Transition>
					</>
				)}
			</Disclosure>
		);
	};

	const renderList = () => {
		return data?.results.map((elm: selectorObj, i: Key) => {
			return elm.nestedItems.length > 0 ? (
				<ParentItem
					item={elm.parentItem}
					nestedItems={elm.nestedItems}
					key={i}
				></ParentItem>
			) : (
				<ChildListItem item={elm.parentItem} key={i}></ChildListItem>
			);
		});
	};
	return (
		<div
			className={clsx(
				'flex flex-col fixed h-[calc(100vh-80px)] px-3 pt-8 border-r-2 border-secondaryBorder mr-[-2px] transition-width ease-linear',
				isExpanded ? 'min-w-[240px] w-60' : 'w-0'
			)}
		>
			<Button
				variant='icon'
				size='icon'
				onClick={handleClick}
				aria-label='Collapsible'
				className={clsx(
					'flex flex-row justify-center w-6 h-6 text-primaryTextLight rounded-full absolute top-[2rem] -right-[0.8rem] z-10',
					isExpanded ? 'bg-secondaryTextDark' : 'bg-primaryBrand'
				)}
				title={`${isExpanded ? 'Hide' : 'Show'} ${title} selector`}
			>
				{isExpanded ? (
					<>
						<span className='sr-only'>Expanded</span>
						<ChevronLeftIcon className={'w-4 h-4'} />
					</>
				) : (
					<>
						<span className='sr-only'>Collapsed</span>
						<ChevronRightIcon className={'w-4 h-4'} />
					</>
				)}
			</Button>
			<div
				className={clsx(
					'transition-visibility ease-linear delay-300 duration-300 overflow-x-hidden',
					isExpanded ? 'w-auto opacity-100 visible ' : 'w-0 opacity-0 invisible'
				)}
			>
				<Typography
					variant='bodyBig'
					className='font-semibold text-primaryTextDark'
					dataTestId='studySelectorTitle'
				>
					{title} name
				</Typography>
				<div className='flex mt-6 mb-8'>
					<label htmlFor='search-study' className='sr-only'>
						{title} Search Value
					</label>
					<Formik
						initialValues={initialValues}
						schema={schema}
						onSubmit={handleSearchSubmit}
					>
						{({ handleChange }) => (
							<Form>
								<Field
									id='search-study'
									type='text'
									name='searchValue'
									placeholder='Search'
									data-test-id='StudySearchInput'
									className='h-10 mr-[-4px] rounded border border-primaryBorder w-[calc(100%+16px)]'
									onChange={(ev: React.ChangeEvent<HTMLInputElement>): void => {
										handleChange(ev);
										handleSearchChange(ev);
									}}
								/>
							</Form>
						)}
					</Formik>
					<div className='flex flex-row justify-center items-center w-10 h-10 text-primaryTextLightwhite bg-primaryBackground border rounded-r'>
						<SearchIcon className={'w-4 h-4'} />
					</div>
				</div>
				{isLoading ? (
					<>
						<SkeletonLoader className='h-6 w-full rounded-sm mb-4' />
						<SkeletonLoader className='h-6 w-full max-w-[170px] rounded-sm mb-4' />
						<SkeletonLoader className='h-6 w-full max-w-[170px] rounded-sm mb-4' />
						<SkeletonLoader className='h-6 w-full max-w-[170px] rounded-sm mb-4' />
						<SkeletonLoader className='h-6 w-full rounded-sm mb-4' />
						<SkeletonLoader className='h-6 w-full max-w-[170px] rounded-sm mb-4' />
						<SkeletonLoader className='h-6 w-full max-w-[170px] rounded-sm mb-4' />
						<SkeletonLoader className='h-6 w-full max-w-[170px] rounded-sm mb-4' />
						<SkeletonLoader className='h-6 w-full rounded-sm mb-4' />
						<SkeletonLoader className='h-6 w-full max-w-[170px] rounded-sm mb-4' />
						<SkeletonLoader className='h-6 w-full max-w-[170px] rounded-sm mb-4' />
						<SkeletonLoader className='h-6 w-full max-w-[170px] rounded-sm mb-4' />
					</>
				) : null}
				{data?.results ? renderList() : null}
			</div>
		</div>
	);
};
