import { API_METHODS } from '@/globalConsts';
import { baseApi } from '@/lib/rtkQuery/baseApi';
import { UserResponse } from '@/types';

import { AUTH } from '../consts';
import {
	LoginRequest,
	LogoutResponse,
	NewPasswordRequest,
	ResetPasswordRequest,
	ResetPasswordResponse,
	TermsAndConditionResponse,
	ValidateTokenRequest,
	ValidateTokenResponse,
} from '../types';

export const authApi = baseApi
	.enhanceEndpoints({ addTagTypes: ['auth', 'session', 'terms'] })
	.injectEndpoints({
		endpoints: (build) => ({
			loginUser: build.mutation<UserResponse, LoginRequest>({
				query: (body) => {
					return {
						url: AUTH.APIS.LOGIN,
						method: API_METHODS.POST,
						body,
					};
				},
			}),
			logoutUser: build.mutation<LogoutResponse, void>({
				query: () => {
					return {
						url: AUTH.APIS.LOGOUT,
						method: API_METHODS.GET,
					};
				},
			}),
			resetPassword: build.mutation<ResetPasswordResponse, ResetPasswordRequest>({
				query: (body) => {
					return {
						url: AUTH.APIS.RESET_PASSWORD,
						method: API_METHODS.POST,
						body,
					};
				},
			}),
			validateToken: build.query<ValidateTokenResponse, ValidateTokenRequest>({
				query: (body) => {
					return {
						url: AUTH.APIS.VALIDATE_TOKEN,
						method: API_METHODS.POST,
						body,
					};
				},
			}),
			newPasswordConfirmation: build.mutation<ResetPasswordResponse, NewPasswordRequest>({
				query: (body) => {
					return {
						url: AUTH.APIS.NEW_PASSWORD_CONFIRMATION,
						method: API_METHODS.POST,
						body,
					};
				},
			}),
			fetchTerms: build.query<TermsAndConditionResponse, void>({
				query: () => {
					return {
						url: AUTH.APIS.FETCH_TERMS,
						method: API_METHODS.GET,
					};
				},
				providesTags: ['terms'],
			}),
		}),
	});

export const {
	useLoginUserMutation,
	useLogoutUserMutation,
	useResetPasswordMutation,
	useValidateTokenQuery,
	useNewPasswordConfirmationMutation,
	useFetchTermsQuery,
} = authApi;
