import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '@/stores/store';

export type LanguageSelectorState = {
	availableLanguages: string[];
	preferredLanguage: string;
	surveyDisplayLanguage: string;
};

const initialState: LanguageSelectorState = {
	preferredLanguage: '',
	surveyDisplayLanguage: '',
	availableLanguages: [],
};

export const LanguageSelectorSlice = createSlice({
	name: 'languageSelector',
	initialState,
	reducers: {
		savePreferredLanguage: (state, { payload }) => {
			const { preferredLanguage } = payload;
			state.preferredLanguage = preferredLanguage;
		},
		saveAvailableLanguages: (state, { payload }) => {
			const { availableLanguages } = payload;
			state.availableLanguages = availableLanguages;
		},
		saveSurveyDisplayLanguage: (state, { payload }) => {
			const { surveyDisplayLanguage } = payload;
			state.surveyDisplayLanguage = surveyDisplayLanguage;
		},
	},
});

export const { saveAvailableLanguages, savePreferredLanguage, saveSurveyDisplayLanguage } =
	LanguageSelectorSlice.actions;
export const selectAvailableLanguages = (state: RootState) =>
	state.languageSelector.availableLanguages;
export const selectPreferredLanguage = (state: RootState) =>
	state.languageSelector.preferredLanguage;
export const selectSurveyDisplayLanguage = (state: RootState) =>
	state.languageSelector.surveyDisplayLanguage;
