import { createSlice } from '@reduxjs/toolkit';
import { nanoid } from 'nanoid';

import { RootState } from '@/stores/store';

export type ReduxDialogProps = {
	type: 'default' | 'confirmation';
	id: string;
	title: string;
	description?: string | undefined;
	isConfirmed?: boolean | undefined;
	confirmButtonLabel?: string | undefined;
};

export type DialogState = {
	dialogs: ReduxDialogProps[];
};

const initialState: DialogState = {
	dialogs: [],
};

export const DialogsSlice = createSlice({
	name: 'notification',
	initialState,
	reducers: {
		addDialog: (state, { payload }) => {
			const { type, title, description, confirmButtonLabel, id = nanoid() } = payload;
			state.dialogs.push({
				type,
				id,
				title,
				description,
				confirmButtonLabel,
			});
		},
		dismissDialogById: (state, { payload }) => {
			const id = payload;
			state.dialogs = state.dialogs.filter((dialog: ReduxDialogProps) => dialog.id !== id);
		},
		dismissDialogInView: (state) => {
			state.dialogs.shift();
		},
		confirmDialog: (state, { payload }) => {
			const { id } = payload;
			const dialog = state.dialogs.find((dialog: ReduxDialogProps) => dialog.id === id);
			if (dialog) {
				dialog.isConfirmed = true;
			}
		},
	},
});

export const { addDialog, confirmDialog, dismissDialogById, dismissDialogInView } =
	DialogsSlice.actions;

export const selectDialogs = (state: RootState) => state.dialogs.dialogs;
export const selectDialogConfirmation = (state: RootState) =>
	state.dialogs.dialogs.length > 0 ? state.dialogs.dialogs[0].isConfirmed : false;
