import { Button, Typography } from '@@/components/Elements';
import { CellContext } from '@tanstack/react-table';
import { PencilOutline, TextBoxSearchOutline } from 'mdi-material-ui';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import { addDialog, DataTableObject, dismissDialogInView, selectDialogs } from '@/components';
import {
	FetchPatientScreeningContextObject,
	PatientScreeningPatientSurveyObject,
} from '@/features/patientScreening';

import { surveyIsEditable } from '../utils';

type CellContextExtended = CellContext<DataTableObject, string> &
	FetchPatientScreeningContextObject;

export const SurveyReviewEditCell = ({
	info,
	isEditEnabled,
}: {
	info: CellContextExtended;
	isEditEnabled: boolean;
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const dialogs = useSelector(selectDialogs);
	const infoCasted = info;
	const { id, survey_type } = infoCasted.row.original as PatientScreeningPatientSurveyObject;
	const isEditable = surveyIsEditable(infoCasted.patientStatus, survey_type);
	const surveyUrl = `./survey-assignment/${id}`;

	useEffect(() => {
		if (dialogs && dialogs.length > 0 && dialogs[0].isConfirmed && dialogs[0].id === id) {
			navigate(`${surveyUrl}?mode=edit`);
			dispatch(dismissDialogInView());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dialogs]);

	const dialogDescription = () => {
		return (
			<>
				<Typography variant='bodyBig' className='text-left'>
					Editing the survey for a patient will delegate consent to fill out the survey to
					uMed. The patient{' '}
					<span className='font-bold'>
						will not be able to complete the survey themselves.
					</span>{' '}
				</Typography>
				<Typography variant='bodyBig' className='text-left'>
					Please confirm you wish to continue. If you wish to view the answers, please
					select the ‘view’ option
				</Typography>
			</>
		);
	};

	const displayConfirmationDialog = () => {
		dispatch(
			addDialog({
				id: id,
				type: 'confirmation',
				title: 'Want to continue?',
				description: dialogDescription(),
				confirmButtonLabel: 'Yes, confirm',
			})
		);
	};

	return (
		<div className='flex flex-row justify-end min-w-[70px]'>
			<Link to={`${surveyUrl}?mode=review`} title='Review' data-test-id='link-survey-review'>
				<Button variant='icon' className='bg-transparent'>
					<TextBoxSearchOutline aria-hidden='true' />
					<span className='sr-only'>Review</span>
				</Button>
			</Link>
			{isEditEnabled && (
				<>
					{isEditable ? (
						<Button
							variant='icon'
							className='bg-transparent !px-0'
							onClick={() => displayConfirmationDialog()}
						>
							<PencilOutline aria-hidden='true' className='cursor-pointer' />
							<span className='sr-only'>Edit</span>
						</Button>
					) : (
						<Button variant='icon' className='bg-transparent !px-0' disabled={true}>
							<PencilOutline aria-hidden='true' />
						</Button>
					)}
				</>
			)}
		</div>
	);
};
