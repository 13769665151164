import { Button } from '@@/components/Elements';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import clsx from 'clsx';
import { Fragment, useEffect } from 'react';

export type DialogProps = {
	close: () => void;
	isOpen: boolean;
	title?: string;
	description?: string;
	className?: string;
	children?: React.ReactNode | string;
	showTopBorder?: boolean;
	dataTestId?: string;
};

export const DialogContainer = (props: DialogProps) => {
	const {
		close,
		isOpen,
		title,
		description,
		className,
		children,
		showTopBorder = true,
		dataTestId = '',
	} = props;

	useEffect(() => {
		if (isOpen) {
			// scroll to far left position to avoid overflow issue caused by overlay
			const top = window.scrollY || document.documentElement.scrollTop;
			window.scrollTo(0, top);
		}
	}, [isOpen]);

	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog as='div' className='relative z-30' onClose={close}>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'
				>
					<div className='fixed inset-0 bg-dialogBackground' />
				</Transition.Child>

				<div className='fixed inset-0 overflow-y-auto'>
					<div className='flex min-h-full items-center justify-center p-4 text-center'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 scale-95'
							enterTo='opacity-100 scale-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100 scale-100'
							leaveTo='opacity-0 scale-95'
						>
							<Dialog.Panel
								className={clsx(
									'transform overflow-hidden rounded-lg bg-primaryBackground border-2 border-secondaryBorder p-6 text-left align-middle transition-all max-w-[1024px]',
									className
								)}
								data-test-id={dataTestId}
							>
								<Button
									variant='icon'
									size='icon'
									onClick={close}
									className='absolute top-6 right-4'
									data-test-id='close-filter-panel-button'
								>
									<XIcon className='w-4 h-4' />
								</Button>
								<Dialog.Title
									as='h4'
									data-test-id={`dialog-title`}
									className='text-lg text-primaryTextDark'
								>
									{title}
								</Dialog.Title>
								{(showTopBorder || description) && (
									<div className='mt-2 pt-4 border-t border-secondaryBorder'>
										<Dialog.Description
											as='p'
											data-test-id={`dialog-description`}
											className='text-sm text-primaryTextDark'
										>
											{description}
										</Dialog.Description>
									</div>
								)}
								<div className='mt-4'>{children}</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	);
};
