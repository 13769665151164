import { CellContext, createColumnHelper } from '@tanstack/react-table';

import { DataTableObject } from '@/components/PatientDataTable';
import { PATIENT_IDENTIFIER } from '@/i18n/consts';
import { PatientObject } from '@/types';
import { formatDate } from '@/utils';
import { formatScreeningStatus } from '@/utils/statusUtils';

import { LastContactedCell } from '../components/LastContactedCell';
import { FetchPatientScreeningObject } from '../types';

type CellContextExtended = CellContext<DataTableObject, string> & FetchPatientScreeningObject;
const columnHelper = createColumnHelper<DataTableObject>();

export const columns = [
	columnHelper.accessor('identifier', {
		header: () => 'uMed ID',
		cell: (info) => info.getValue(),
		enableSorting: true,
	}),
	columnHelper.accessor('nhs_number', {
		header: () => PATIENT_IDENTIFIER,
		cell: (info) => info.getValue() || null,
		enableSorting: true,
	}),
	columnHelper.accessor(
		(row) =>
			`${(row as FetchPatientScreeningObject).patient_first_name} ${
				(row as FetchPatientScreeningObject).patient_last_name
			}`,
		{
			header: () => 'Patient Name',
			id: 'name',
			enableSorting: true,
		}
	),
	columnHelper.display({
		id: 'status',
		header: () => 'Status',
		cell: (props) => {
			const { Element } = formatScreeningStatus(
				props.row.original as PatientObject,
				'screening'
			);
			return Element;
		},
		enableSorting: true,
	}),
	columnHelper.display({
		id: 'last_contact_at',
		header: () => 'Last contact',
		cell: (props) => <LastContactedCell props={props as CellContextExtended} />,
		enableSorting: true,
	}),
	columnHelper.accessor('contact_booked_for', {
		header: () => 'Call booked',
		cell: (info) => formatDate(info.getValue() as string) || null,
		enableSorting: true,
	}),
	columnHelper.accessor('provider_name', {
		header: () => 'Provider',
		cell: (info) => info.getValue() || null,
		enableSorting: true,
	}),
	columnHelper.accessor('contact_email', {
		header: () => 'Email',
		cell: (info) => info.getValue() || null,
		enableSorting: true,
	}),
	columnHelper.accessor('date_of_birth', {
		header: () => 'Date of birth',
		cell: (info) => formatDate(info.getValue() as string),
		enableSorting: true,
	}),
	columnHelper.accessor('contact_mobile_number', {
		header: () => 'Mobile number',
		cell: (info) => info.getValue() || null,
		enableSorting: true,
	}),
];

export const rowProps = {
	batchSelect: false,
	onRowClickTarget: '/patient-screening/',
	rowSelect: true,
	getRowId: (row: DataTableObject) => (row as FetchPatientScreeningObject).id,
};
