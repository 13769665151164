import {
	AccountSearchOutline,
	CalendarOutline,
	PhoneOutline,
	ThumbDownOutline,
	ThumbUpOutline,
} from 'mdi-material-ui';

import { PATIENT_IDENTIFIER } from '@/i18n/consts';

export const TRIAL_SITES = {
	APIS: {
		PATIENTS_LIST: 'v1/patient-randomising/',
		TRAILING_SLASH: '/',
		COMMIT: '/commit/',
		NOTES: '/note/',
		FETCH_PREDEFINED_NOTE: 'v1/predefined-note/?all=true',
	},
	ROUTES: {
		PATIENT_DETAILS: {
			HEAD_TITLE: 'Patient Details',
			TITLE: 'Patient Details',
		},
		STATUS_UPDATE: {
			HEAD_TITLE: 'Status update',
		},
		TRIAL_SITES_CONTAINER: {
			HEAD_TITLE: 'Trial Sites',
		},
	},
	COMPONENTS: {
		CREATE_TRIAL_SITE_NOTE: {
			FETCH_NOTES_MODAL: {
				TITLE: 'Create a new note',
				BODY: 'This note will be visible for all admin users in this Trial Site as well as uMed operators',
			},
			FETCH_NOTES_FORM: {
				CANCEL_BUTTON: 'Cancel',
				SUBMIT_BUTTON: 'Create',
			},
			FETCH_NOTES_SUCCESS_NOTIFICATION: {
				TITLE: 'Success',
				BODY: 'You have successfully created a new note',
				TIMEOUT_LENGTH: 8000,
			},
		},
		PATIENT_LIST_CONTAINER: {
			TABS: {
				A: {
					LABEL: 'All patients',
				},
				B: {
					LABEL: 'New',
				},
				C: {
					LABEL: 'In progress',
				},
				D: {
					LABEL: 'Randomised',
				},
				E: {
					LABEL: 'Excluded',
				},
			},
		},
		PATIENT_LIST: {
			DATA_TABLE_TITLE: 'Trial Sites',
		},
		TRIAL_SITE_SCREENING_RESULT: {
			TITLE: 'Please enter the result of the patient screening',
			BODY: 'If you set a patient as Randomised or Excluded, you won’t be able to modify results.',
			SAVE_RESULT_FORM: {
				SUBMIT_BUTTON: 'Commit',
				CANCEL_BUTTON: 'Cancel',
			},
			SAVE_RESULT_CONFIRMATION_MODAL: {
				DESCRIPTION: {
					PARAGRAPHS: {
						A: 'You are about to set this patient as',
						B: 'this action cannot be undone. Are you sure?',
					},
				},
				CANCEL_BUTTON: 'No, cancel',
				CONFIRM_BUTTON: 'Yes, commit',
			},
			SAVE_RESULT_SUCCESS_NOTIFICATION: {
				TITLE: 'Success',
				BODY: 'You have successfully saved the trial screening result.',
				TIMEOUT_LENGTH: 8000,
			},
		},
	},
	CONFIGS: {
		PATIENT_DETAILS: {
			BUTTON: {
				LINK: './complete-testing',
				LABEL: 'Update Status',
			},
			TABLE: {
				COLUMNS: {
					A: {
						LABEL: 'Umed ID',
					},
					B: {
						LABEL: 'Partner ID',
					},
					C: {
						LABEL: 'Status',
					},
					D: {
						LABEL: 'Reason',
					},
				},
			},
			TABS: {
				DEFAULT_TAB: 1,
			},
		},
		RADIO_BUTTON_GROUP: {
			RADIO_BUTTON: {
				A: {
					ICON: <PhoneOutline fontSize='large' />,
					LABEL: 'Contacted',
					VALUE: 50,
				},
				B: {
					ICON: <CalendarOutline fontSize='large' />,
					LABEL: 'Scheduled',
					VALUE: 100,
				},
				C: {
					ICON: <AccountSearchOutline fontSize='large' />,
					LABEL: 'Screening',
					VALUE: 150,
				},
				D: {
					ICON: <ThumbUpOutline fontSize='large' />,
					LABEL: 'Randomised',
					VALUE: 200,
				},
				E: {
					ICON: <ThumbDownOutline fontSize='large' />,
					LABEL: 'Excluded',
					VALUE: 300,
				},
			},
		},
		PATIENT_LIST: {
			TABLE: {
				COLUMNS: {
					A: {
						LABEL: 'uMed ID',
					},
					B: {
						ID: 'status',
						LABEL: 'Status',
					},
					C: {
						ID: 'name',
						LABEL: 'Patient Name',
					},
					D: {
						LABEL: PATIENT_IDENTIFIER,
					},
					E: {
						LABEL: 'Provider',
					},
				},
				ROW_CLICK_TARGET: '/trial-sites/',
			},
		},
		SURVEY_LIST: {
			TABLE: {
				COLUMNS: {
					A: {
						LABEL: 'Survey name',
					},
					B: {
						ID: 'review_edit',
					},
				},
			},
		},
	},
};
