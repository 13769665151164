import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';

import { DialogsSlice } from '@/components/Dialogs';
import { LanguageSelectorSlice } from '@/components/LanguageDropdown';
import { NotificationSlice } from '@/components/Notifications/notificationsSlice';
import { DataTableSlice } from '@/components/PatientDataTable/store/dataTableSlice';
import { baseApi } from '@/lib/rtkQuery/baseApi';

import { FeatureDefinitionsSlice } from './featureFlagsSlice';
import { listenerMiddleware, rtkQueryErrorHandler } from './middleware';

export const store = configureStore({
	reducer: {
		dataTable: DataTableSlice.reducer,
		dialogs: DialogsSlice.reducer,
		featureDefinitions: FeatureDefinitionsSlice.reducer,
		languageSelector: LanguageSelectorSlice.reducer,
		notifications: NotificationSlice.reducer,
		[baseApi.reducerPath]: baseApi.reducer,
	},
	middleware: (getDefaultMiddleware) => [
		...getDefaultMiddleware().concat(baseApi.middleware),
		listenerMiddleware.middleware,
		rtkQueryErrorHandler,
	],
	devTools: import.meta.env.VITE_SITE_ENV !== 'prod',
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
setupListeners(store.dispatch);
