import { baseApi } from '@/lib/rtkQuery/baseApi';

export const patientIdValidationApi = baseApi.injectEndpoints({
	endpoints: (build) => ({
		validatePatientIds: build.mutation<{ valid: string[]; invalid: string[] }, string[]>({
			query: (patientIdsArr) => {
				return {
					url: `v1/patient-list/validate-patient-ids/`,
					method: 'POST',
					body: patientIdsArr,
				};
			},
		}),
	}),
});

export const { useValidatePatientIdsMutation } = patientIdValidationApi;
