import { SelectOption, selectorObj } from '@/components';
import { HciListsObject } from '@/features/healthCareInstitution';

import {
	AllPatientListObject,
	PatientListFilterFormValues,
	SearchCriteriaListObject,
	SurveyFiltersType,
	SurveyListObject,
	dynamicFilterListType,
	filtersDefaultObjTypes,
	formatFiltersTypes,
} from '../types';

export const generateSurveySelectOptions = (
	surveyListResults: SurveyListObject[],
	returnValueAsObject?: boolean
) => {
	const surveySelectOptions = surveyListResults?.map((study) => {
		return {
			label: study.name,
			options: study?.survey_versions?.map((survey) => {
				const surveyName = `${survey.survey_name} v${survey.version}`;
				return {
					label: surveyName,
					value: returnValueAsObject ? { name: surveyName, id: survey.id } : survey.id,
				};
			}),
		};
	});
	return surveySelectOptions;
};

export const generateStudySelectOptions = (studiesResults: selectorObj[]) => {
	const studySelectOptions = [] as SelectOption[];
	studiesResults?.forEach((study) => {
		const parentStudy = {
			label: study.parentItem.name,
			value: { name: study.parentItem.name, id: study.parentItem.id },
		};
		studySelectOptions.push(parentStudy);
		study.nestedItems.forEach((nestedItem) => {
			const subStudy = {
				label: (
					<>
						<span className='text-disabledText pr-1'>{study.parentItem.name} &gt;</span>
						{nestedItem.name}
					</>
				),
				value: { name: nestedItem.name, id: nestedItem.id },
			};
			studySelectOptions.push(subStudy);
		});
	});
	return studySelectOptions;
};

export const generateSearchCriteriaOptions = (
	searchCriteriaResults: SearchCriteriaListObject[]
) => {
	return searchCriteriaResults?.map((s) => {
		return {
			label: s.name.length >= 1 ? s.name : 'Unknown',
			value: {
				name: s.name.length >= 1 ? s.name : 'Unknown',
				id: s.id,
			},
		};
	});
};

export const renderHciNameStatus = (name: string, isActive: boolean, containerClass?: string) => {
	return (
		<span
			className={`!flex items-center
				before:content-[' '] before:inline-block before:h-[6px] before:w-[6px] before:min-w-[6px] before:rounded-[6px] before:mr-[10px] before:mb-[2px]
				${containerClass || ''}
				${isActive ? 'before:bg-success' : 'before:bg-error'}
			`}
			title={isActive ? 'Active' : 'Inactive'}
		>
			{name}
		</span>
	);
};

export const generateHciListOptions = (hciListResults: HciListsObject[]) => {
	return hciListResults?.map((item) => {
		return {
			label: item.name.length >= 1 ? item.name : 'Unknown',
			value: {
				name: item.name.length >= 1 ? item.name : 'Unknown',
				id: item.id,
			},
			isActive: item.status === 'ACTIVE',
		};
	});
};

export const generatePatientListOptions = (patientListResults: AllPatientListObject[]) => {
	return patientListResults?.map((list) => {
		return {
			label: list.name.length >= 1 ? list.name : '',
			value: {
				name: list.name.length >= 1 ? list.name : 'Unknown',
				id: list.id,
			},
		};
	});
};

const setupDynamicFilters = (
	values: PatientListFilterFormValues,
	filterKey: 'survey_filters' | 'contact_filters',
	defaultObj: filtersDefaultObjTypes
) => {
	const filters = { ...values };
	if ((filters[filterKey] as string[])?.length === 0) {
		// add initial empty filter for display only
		(filters[filterKey] as [filtersDefaultObjTypes]) = [defaultObj];
	} else if ((filters[filterKey] as string[])?.length > 0) {
		const thisFilters = filters[filterKey] as string[];
		filters[filterKey] = thisFilters.map((filter) => {
			if (typeof filter === 'string') {
				// parse value as it has been saved as a string
				return JSON.parse(filter);
			}
			return filter;
		});
	}
	return filters;
};

export const setupAllDynamicFilters = (
	filterValues: PatientListFilterFormValues,
	dynamicFiltersList: dynamicFilterListType[]
) => {
	let dynamicFilters = filterValues;
	dynamicFiltersList.forEach((dynamicFilter) => {
		dynamicFilters = setupDynamicFilters(
			dynamicFilters,
			dynamicFilter.name,
			dynamicFilter.defaultObj
		);
	});
	return dynamicFilters;
};

const formatFiltersForSaving = (
	values: PatientListFilterFormValues,
	filterKey: 'survey_filters' | 'contact_filters',
	filterValidationFieldName: keyof formatFiltersTypes
) => {
	const filterValues = values;
	let hasFilters = true;
	// strip out initial empty filter as it's for display only
	if (filterValues && filterValues[filterKey]?.length === 1) {
		const firstFilter = filterValues[filterKey][0];
		if (typeof firstFilter !== 'string' && firstFilter.filter_type.length === 0) {
			hasFilters = false;
			filterValues[filterKey] = [];
		}
	}
	if (hasFilters) {
		const filters = filterValues[filterKey];
		// stringify value before saving in state as multi dimensional array was causing an issue with Redux state
		// remove incomplete filters first to avoid empty values being sent
		filterValues[filterKey] = (filters as formatFiltersTypes[])
			.filter((f) => f[filterValidationFieldName]?.length > 0)
			.map((f) => JSON.stringify(f));
	}
	return filterValues;
};

export const formatAllFiltersForSaving = (
	filterValues: PatientListFilterFormValues,
	dynamicFiltersList: dynamicFilterListType[]
) => {
	let formattedFilters = filterValues;
	dynamicFiltersList.forEach((dynamicFilter) => {
		formattedFilters = formatFiltersForSaving(
			formattedFilters,
			dynamicFilter.name,
			dynamicFilter.validationKeyName
		);
	});
	return formattedFilters;
};

export const surveyFiltersDefaultObj = {
	filter_type: '',
	survey_id: {
		name: '',
		id: '',
	},
	survey_status: '',
};

export const contactFiltersDefaultObj = {
	filter_type: 'include',
	is_null: '',
	name: '',
};

export const hasCompletedSurvey = (values: PatientListFilterFormValues) => {
	return values.survey_filters?.some(
		(surveyFilter) => (surveyFilter as SurveyFiltersType)?.survey_status === 'completed'
	);
};
