import { Typography, Button } from '@@/components/Elements';
import parse from 'html-react-parser';
import { useDispatch } from 'react-redux';

import { CheckboxField } from '@/components/Form';
import { TermsAndConditions } from '@/components/Layout/TermsAndConditions';
import { useDisclosure } from '@/hooks/useDisclosure';

import { authApi, useFetchTermsQuery } from '../api/authApi';
import { AUTH } from '../consts';

type UserTermsAndConditionsProps = {
	name: string;
};

export const UserTermsAndConditions: React.FC<UserTermsAndConditionsProps> = ({ name }) => {
	const { close, isOpen, open } = useDisclosure();
	const { data } = useFetchTermsQuery();
	const dispatch = useDispatch();
	const closeTermsDialog = () => {
		dispatch(authApi.util.invalidateTags(['terms']));
		close();
	};
	return (
		<>
			<div className='flex flex-row items-baseline mt-4'>
				<CheckboxField label='' name={name} />
				<>
					<Typography variant='body' className='text-primaryTextDark'>
						{AUTH.COMPONENTS.TERMS_AND_CONDITIONS.CHECKBOX.LABEL}
					</Typography>
					<button type='button' onClick={open}>
						<Typography variant='link' className='text-primaryTextDark ml-1'>
							{AUTH.COMPONENTS.TERMS_AND_CONDITIONS.CHECKBOX.LINK}
						</Typography>
					</button>
				</>
			</div>
			<TermsAndConditions
				isOpen={isOpen}
				close={closeTermsDialog}
				title={AUTH.COMPONENTS.TERMS_AND_CONDITIONS.MODAL.TITLE}
			>
				{data ? <div className='p-4'>{parse(data.terms)}</div> : null}
				<div className='flex justify-between mt-4'>
					<Button variant='secondary' onClick={closeTermsDialog}>
						{AUTH.COMPONENTS.TERMS_AND_CONDITIONS.MODAL.CLOSE_BUTTON.LABEL}
					</Button>
				</div>
			</TermsAndConditions>
		</>
	);
};
