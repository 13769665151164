import { Button } from '@@/components/Elements';
import { Dialog } from '@headlessui/react';
import { Close } from 'mdi-material-ui';

import { ReduxDialogProps } from './dialogsSlice';

interface DefaultDialogProps extends Omit<ReduxDialogProps, 'type'> {
	onDismiss: (id: string) => void;
}

export const DefaultDialog = (props: DefaultDialogProps) => {
	const { id, title, description, onDismiss } = props;
	return (
		<Dialog.Panel className='transform overflow-hidden rounded-lg bg-primaryBackground border-2 border-secondaryBorder p-6 transition-all'>
			<div className='flex flex-row justify-between align-middle mb-2 pb-4 border-b border-secondaryBackground'>
				<Dialog.Title as='h4' data-test-id={`dialog-title`} className='text-lg'>
					{title}
				</Dialog.Title>
				<Button
					variant='icon'
					size='icon'
					className='px-0 py-0'
					onClick={() => onDismiss(id)}
				>
					<Close className='w-4 h-4' />
				</Button>
			</div>
			<Dialog.Description
				as='p'
				data-test-id={`dialog-description`}
				className='text-md mt-2 mb-4 pt-4 pb-6'
			>
				{description}
			</Dialog.Description>
			<div className='flex flex-row justify-end'>
				<Button variant='primary' size='md' onClick={() => onDismiss(id)}>
					Ok
				</Button>
			</div>
		</Dialog.Panel>
	);
};
