import clsx from 'clsx';
import { Close } from 'mdi-material-ui';

const variants = {
	withIntersection: ': ',
	noIntersection: '',
};

const styles = {
	default: 'bg-highlight',
	taggedSearchCriteria: 'bg-taggedSearchCriteria',
	valid: 'bg-valid',
	invalid: 'bg-invalid',
};

export type ChipProps = {
	onClose?: () => void;
	name?: string;
	value?: string;
	variant?: keyof typeof variants;
	style?: keyof typeof styles;
};

export const Chip = (props: ChipProps) => {
	const { onClose, name, value, variant = 'withIntersection', style = 'default' } = props;
	return (
		<div
			className={clsx('p-2 rounded-md text-base text-primaryTextDark', styles[style])}
			data-test-id='chip'
		>
			{name && (
				<span>
					{name}
					{variants[variant]}
				</span>
			)}
			<span className={`${name ? 'font-semibold' : ''}`}>{value}</span>
			{onClose && (
				<span>
					<Close
						fontSize='small'
						className='ml-4 float-right cursor-pointer'
						onClick={onClose}
					></Close>
				</span>
			)}
		</div>
	);
};
