import { createColumnHelper } from '@tanstack/react-table';

import { DataTableObject, PersonDataObject } from '@/components/PatientDataTable';
import { PATIENT_IDENTIFIER } from '@/i18n/consts';
import { formatDate } from '@/utils';
import { formatScreeningStatus } from '@/utils/statusUtils';

import { PatientListPreview } from '../components/Patients/PatientListPreview';
import { CellContextExtended, CriteriaObject, SearchCriteriaObject, StudiesObject } from '../types';
import { renderHciNameStatus } from '../utils';

const columnHelper = createColumnHelper<DataTableObject>();

type displayArrayItemTypes = StudiesObject | SearchCriteriaObject | CriteriaObject;

const renderStatusElement = (data: StudiesObject) => {
	const { Element } = formatScreeningStatus(data);
	return Element;
};

const renderConsentDate = (data: StudiesObject) => {
	return <>{formatDate(data.consent_date as string)}</>;
};

const renderHci = (data: StudiesObject) => {
	return renderHciNameStatus(data.hci_name, data.hci_active);
};

const displayArrayItem = (
	arr: displayArrayItemTypes[],
	accessor: string,
	formatFunction?: (data: StudiesObject) => JSX.Element
) => {
	if (!arr || arr.length === 0) return <span>-</span>;
	return arr.length > 1
		? `+${arr.length}`
		: typeof formatFunction === 'function'
		? formatFunction(arr[0] as StudiesObject)
		: arr[0][accessor as keyof displayArrayItemTypes];
};

export const patientListColumns = [
	columnHelper.accessor(
		(row) => `${(row as PersonDataObject).first_name} ${(row as PersonDataObject).last_name}`,
		{
			id: 'name',
			header: () => 'Patient Name',
		}
	),
	columnHelper.accessor('identifier', {
		id: 'patient_identifier',
		header: () => PATIENT_IDENTIFIER,
	}),
	columnHelper.display({
		id: 'umed_id',
		header: () => 'uMed ID',
		cell: (props) =>
			displayArrayItem(
				(props.row.original as PersonDataObject).studies,
				'patient_identifier'
			),
	}),
	columnHelper.accessor('birth_date', {
		id: 'birth_date',
		header: () => 'Date of birth',
		cell: (props) => {
			return formatDate(props.getValue() as string);
		},
	}),
	columnHelper.display({
		id: 'study_name',
		header: () => 'Study/Cohort',
		cell: (props) => displayArrayItem((props.row.original as PersonDataObject).studies, 'name'),
	}),
	columnHelper.display({
		id: 'consent_date',
		header: () => 'Consent date',
		cell: (props) =>
			displayArrayItem(
				(props.row.original as PersonDataObject).studies,
				'consent_date',
				renderConsentDate
			),
	}),
	columnHelper.display({
		id: 'status',
		header: () => 'Patient status',
		cell: (props) =>
			displayArrayItem(
				(props.row.original as PersonDataObject).studies,
				'status_label',
				renderStatusElement
			),
	}),
	columnHelper.display({
		id: 'hci_name',
		header: () => 'HCI',
		cell: (props) =>
			displayArrayItem(
				(props.row.original as PersonDataObject).studies,
				'hci_name',
				renderHci
			),
	}),
	columnHelper.display({
		id: 'search_criteria',
		header: () => 'Tagged search criteria',
		cell: (props) =>
			displayArrayItem((props.row.original as PersonDataObject).search_criteria, 'name'),
	}),
	columnHelper.accessor('gender', {
		id: 'gender',
		header: () => 'Gender',
		cell: (info) => {
			return <p className='capitalize'>{info.getValue()}</p>;
		},
	}),
	columnHelper.accessor('contact_info.last_contact_at', {
		header: () => 'Last contact at',
		id: 'last_contact_at',
		cell: (info) => {
			return formatDate(info.getValue() as string);
		},
	}),
	columnHelper.accessor(
		(row) =>
			`${(row as PersonDataObject).contact_info.last_contact_by_first_name} ${
				(row as PersonDataObject).contact_info.last_contact_by_last_name
			}`,
		{
			header: () => 'Last contact by name',
			id: 'last_contact_by_name',
		}
	),
	columnHelper.accessor('contact_info.number_of_contact_attempts', {
		id: 'number_of_contact_attempts',
		header: () => 'Number of followups',
	}),
	columnHelper.accessor('contact_info.contact_booked_for', {
		id: 'contact_booked_for',
		header: () => 'Call booked',
		cell: (info) => {
			return formatDate(info.getValue() as string);
		},
	}),
	columnHelper.display({
		id: 'action',
		header: () => 'Action',
		cell: (props) => <PatientListPreview props={props as CellContextExtended} />,
	}),
];

export const patientListRowProps = {
	rowSelect: true,
	onRowClickTarget: '/patient-lists/patients/',
	getRowId: (row: PersonDataObject) =>
		`${row.studies.find((study) => study.cancelled === 0)?.patient_id || 0}`,
};

export const patientListTabValueDefault = 0;

export const patientListColumnVisibility = {
	contact_booked_for: false,
	umed_id: false,
	last_contact_at: false,
	last_contact_by_name: false,
	number_of_contact_attempts: false,
};
