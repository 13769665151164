import { Typography } from '@@/components/Elements';
import clsx from 'clsx';
import { FieldHookConfig, useField } from 'formik';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type TextAreaFieldProps = FieldWrapperPassThroughProps & {
	className?: string;
	placeholder?: string;
	rows?: number;
	cols?: number;
	disabled?: boolean;
	maxLength?: number;
	dataTestId?: string;
};

export const TextAreaField = (props: TextAreaFieldProps & FieldHookConfig<string>) => {
	const {
		label,
		name,
		placeholder,
		rows,
		cols,
		width,
		className,
		disabled = false,
		maxLength,
		dataTestId,
	} = props;
	const [field, meta] = useField(props);
	const { error, touched, value } = meta;

	return (
		<FieldWrapper
			error={error}
			name={name}
			touched={touched}
			label={label}
			width={width}
			disabled={disabled}
		>
			<textarea
				id={name}
				rows={rows}
				cols={cols}
				data-test-id={dataTestId}
				placeholder={placeholder}
				{...field}
				className={clsx(
					'flex-1 min-w-0 block w-full p-3 text-base rounded border-primaryBorder placeholder:text-base placeholder:text-disabledText',
					error && touched
						? 'text-error border-error placeholder:text-error focus:ring-error focus:border-error'
						: 'focus:ring-primaryBrand focus:border-primaryBrand',
					disabled
						? 'bg-disabledBackground border-primaryBorder cursor-not-allowed text-primaryTextDark'
						: '',
					className
				)}
				disabled={disabled}
				aria-invalid='true'
				aria-describedby='text-area-error'
			/>
			{maxLength ? (
				<Typography variant='bodySmall' className='text-secondaryTextDark'>
					{value.length}/{maxLength}
				</Typography>
			) : null}
		</FieldWrapper>
	);
};
