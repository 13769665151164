import { lazy } from 'react';

const SurveyRoutes = lazy(() =>
	import('@/features/survey').then(({ SurveyRoutes }) => ({ default: SurveyRoutes }))
);

const Maintenance = lazy(() =>
	import('@/features/maintenance').then(({ Maintenance }) => ({
		default: Maintenance,
	}))
);

// these routes are separate as they need to be accessible for logged-in AND logged-out users

export const patientFacingRoutes = [
	{
		path: '/survey/*',
		element: <SurveyRoutes />,
		doNotLoadAnalytics: true, // Do not load analytics from the app route on this page. Analytics are loaded from SurveyContainer instead to ensure user's language is set.
	},
	{
		path: '/r/*',
		element: <SurveyRoutes />,
		doNotLoadAnalytics: true, // Do not load analytics from the app route on this page.
	},
	{
		path: '/maintenance',
		element: <Maintenance />,
		doNotLoadAnalytics: true, // Do not load analytics from the app route on this page. Analytics are not required on maintenance page.
	},
];
