import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DialogComponent } from './DialogComponent';
import { ReduxDialogProps, dismissDialogById, selectDialogs } from './dialogsSlice';

export const DialogsContainer = () => {
	const dispatch = useDispatch();
	const dialogs = useSelector(selectDialogs);
	const filteredDialogs = dialogs.filter(
		(dialog: ReduxDialogProps, index: number, self: ReduxDialogProps[]) =>
			index ===
			self.findIndex(
				(n) => JSON.stringify(n.description) === JSON.stringify(dialog.description)
			)
	);

	const onDismiss = (id: string) => {
		dispatch(dismissDialogById(id));
	};

	return (
		<Transition appear show={filteredDialogs && filteredDialogs.length > 0} as={Fragment}>
			<Dialog
				as='div'
				className='relative z-30'
				// this should always be whatever dialog is on 'top' check that this work correctly
				onClose={() => onDismiss(filteredDialogs[0].id)}
			>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'
				>
					<div className='fixed inset-0 bg-dialogBackground' />
				</Transition.Child>

				<div className='fixed inset-0 overflow-y-auto'>
					<div className='flex min-h-full items-center justify-center p-4 text-center'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 scale-95'
							enterTo='opacity-100 scale-100'
							leave='ease-in duration-200'
							leaveFrom='opacity-100 scale-100'
							leaveTo='opacity-0 scale-95'
						>
							<div>
								{filteredDialogs && filteredDialogs[0] && (
									<DialogComponent
										key={filteredDialogs[0].id}
										id={filteredDialogs[0].id}
										type={filteredDialogs[0].type}
										title={filteredDialogs[0].title}
										description={filteredDialogs[0].description}
										confirmButtonLabel={filteredDialogs[0].confirmButtonLabel}
										onDismiss={onDismiss}
									/>
								)}
							</div>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	);
};
