import { Typography } from '@@/components/Elements';
import { createColumnHelper } from '@tanstack/react-table';

import { formatLongDateTime } from '@/utils';

import { PATIENT_DETAILS } from '../consts';
import { FetchPatientNoteHistoryLogResponse } from '../types';

const columnHelper = createColumnHelper<FetchPatientNoteHistoryLogResponse>();

export const columns = [
	columnHelper.accessor<'created_at', string>('created_at', {
		header: () => (
			<Typography variant='bodySmall'>
				{PATIENT_DETAILS.CONFIGS.PATIENT_HISTORY.TABLE.COLUMNS.A.LABEL}
			</Typography>
		),
		cell: (info) => {
			return formatLongDateTime(info.getValue());
		},
	}),
	columnHelper.accessor<'note_content', string>('note_content', {
		header: () => (
			<Typography variant='bodySmall'>
				{PATIENT_DETAILS.CONFIGS.PATIENT_HISTORY.TABLE.COLUMNS.B.LABEL}
			</Typography>
		),
		cell: (info) => info.getValue() || null,
	}),
	columnHelper.accessor<'created_by_name', string>('created_by_name', {
		header: () => (
			<Typography variant='bodySmall'>
				{PATIENT_DETAILS.CONFIGS.PATIENT_HISTORY.TABLE.COLUMNS.C.LABEL}
			</Typography>
		),
		cell: (info) => info.getValue() || null,
	}),
];
