import { createColumnHelper } from '@tanstack/react-table';

import { StudyProgressObject } from '../types';

import { columns } from '.';

const columnHelper = createColumnHelper<StudyProgressObject>();

export const studyTabColumns = [
	columnHelper.accessor('group_name', {
		header: () => 'Study',
	}),
	...columns,
];

export const cohortSubgroupTabColumns = [
	columnHelper.accessor('group_name', {
		header: () => 'Cohort subgroup',
	}),
	...columns,
];

export const assignedTrialSiteTabColumns = [
	columnHelper.accessor('group_name', {
		header: () => 'Assigned Trial Site',
	}),
	...columns,
];

export const genderTabColumns = [
	columnHelper.accessor('group_name', {
		header: () => 'Gender',
	}),
	...columns,
];

export const careOrgTabColumns = [
	columnHelper.accessor('group_name', {
		header: () => 'Care Organisation',
	}),
	...columns,
];

export const letterSentTabColumns = [
	columnHelper.accessor('group_name', {
		header: () => 'Letter Sent',
	}),
	...columns,
];

export const studyProgressPatientListDefaultTab = 0;
