import { baseApi } from '@/lib/rtkQuery/baseApi';
import {
	CareOrganizationsRequest,
	CareOrganizationsResponse,
	FeatureFlagsResponseObject,
	PatientTagsRequest,
	PatientTagsResponse,
	SessionResponse,
	UserResponse,
} from '@/types';

export const patientContactApi = baseApi
	.enhanceEndpoints({ addTagTypes: ['auth', 'session'] })
	.injectEndpoints({
		endpoints: (build) => ({
			fetchSession: build.query<SessionResponse, void>({
				query: () => {
					return {
						url: 'v1/auth/session/',
						method: 'get',
					};
				},
				providesTags: ['session'],
			}),
			fetchUser: build.query<UserResponse, void>({
				query: () => {
					return {
						url: 'v1/auth/me/',
						method: 'get',
					};
				},
				providesTags: ['auth'],
			}),
			fetchCareOrganization: build.query<CareOrganizationsResponse, CareOrganizationsRequest>(
				{
					query: ({ is_partnership, for_study }) => {
						return {
							url: `v1/care-organisations/?all=true&for_study=${for_study}&is_partnership=${is_partnership}`,
							method: 'GET',
						};
					},
					transformResponse: (response: CareOrganizationsResponse) => {
						return Array.isArray(response)
							? { results: response, total: response.length }
							: response;
					},
				}
			),
			fetchPatientTags: build.query<PatientTagsResponse, PatientTagsRequest>({
				query: ({ valid_for_study }) => {
					return {
						url: `v1/patient-tagging/tags/?valid_for_study=${valid_for_study}`,
						method: 'GET',
					};
				},
			}),
			fetchFeatureFlags: build.query<FeatureFlagsResponseObject, void>({
				query: () => {
					return {
						url: 'v1/feature-flagging/status',
						methods: 'GET',
					};
				},
			}),
		}),
	});

export const {
	useFetchFeatureFlagsQuery,
	useFetchSessionQuery,
	useFetchUserQuery,
	useFetchCareOrganizationQuery,
	useFetchPatientTagsQuery,
} = patientContactApi;
