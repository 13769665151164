import {
	FileRefreshOutline,
	HandBackRightOffOutline,
	ThumbDownOutline,
	ThumbUpOutline,
} from 'mdi-material-ui';

export const radioButtons = [
	{
		icon: <ThumbUpOutline fontSize='large' />,
		label: 'Pass',
		value: 200,
		activeClasses: 'text-success border border-success',
	},
	{
		icon: <ThumbDownOutline fontSize='large' />,
		label: 'Fail',
		value: 300,
		activeClasses: 'text-error border border-error',
	},
	{
		icon: <FileRefreshOutline fontSize='large' />,
		label: 'Re-screening required',
		value: 500,
		activeClasses: 'text-info border border-info',
	},
	{
		icon: <HandBackRightOffOutline fontSize='large' />,
		label: 'Opted out',
		value: 1000,
		activeClasses: 'text-warning border border-warning',
	},
];
export const formatSurveyResults = (result = '') => {
	switch (result) {
		case 'pass':
			return 'text-success';
		case 'fail':
			return 'text-error';
		case 're-screening':
			return 'text-info';
		case 'opt-out':
			return 'text-warning';
		default:
			return 'text-primaryBrand';
	}
};
export const surveyResultOptions = [
	{ label: 'Screening passed', value: 'pass' },
	{ label: 'Screening failed', value: 'fail' },
	{ label: 'Opted out', value: 'opt-out' },
	{ label: 'Requires re-screening', value: 're-screening' },
];

export const PATIENT_SCREENING = {
	ROUTES: {
		PATIENT_DETAILS: {
			HEAD_TITLE: 'Patient Details',
			TITLE: 'Patient Details',
		},
		EDIT_PATIENT_DETAILS: {
			HEAD_TITLE: 'Edit Patient Details',
			TITLE: 'Edit Patient Details',
		},
		PATIENT_SCREENING_CONTAINER: {
			HEAD_TITLE: 'Patient Screening',
		},
		SURVEY_ASSIGNMENT: {
			HEAD_TITLE: 'Survey assignment',
		},
	},
};
