import clsx from 'clsx';

export type SkeletonLoaderProps = {
	className: string;
};

export const SkeletonLoader = (props: SkeletonLoaderProps) => {
	const { className } = props;
	return <div className={clsx('animate-pulse bg-skeletonLoaderBackground', className)}></div>;
};
