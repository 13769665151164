// Dashboard icons
import iconClinicalAdmin from '@/assets/menu/icon_dashboard_clinical_admin.svg';
import iconHci from '@/assets/menu/icon_dashboard_hci.svg';
import iconPatientApproval from '@/assets/menu/icon_dashboard_patient_approval.svg';
import iconPatientContact from '@/assets/menu/icon_dashboard_patient_contact.svg';
import iconPatientEngagement from '@/assets/menu/icon_dashboard_patient_engagement.svg';
import iconPatientList from '@/assets/menu/icon_dashboard_patient_lists.svg';
import iconPatientScreening from '@/assets/menu/icon_dashboard_patient_screening.svg';
import iconReporting from '@/assets/menu/icon_dashboard_reporting.svg';
import iconTrialSite from '@/assets/menu/icon_dashboard_trial_site.svg';
// Menu active icons
import iconMenuAciveClinicalAdmin from '@/assets/menu/icon_menu_active_clinical_admin.svg';
import iconMenuActiveHci from '@/assets/menu/icon_menu_active_hci.svg';
import iconMenuActivePatientApproval from '@/assets/menu/icon_menu_active_patient_approval.svg';
import iconMenuActivePatientContact from '@/assets/menu/icon_menu_active_patient_contact.svg';
import iconMenuActivePatientEngagement from '@/assets/menu/icon_menu_active_patient_engagement.svg';
import iconMenuActivePatientLists from '@/assets/menu/icon_menu_active_patient_lists.svg';
import iconMenuActivePatientScreening from '@/assets/menu/icon_menu_active_patient_screening.svg';
import iconMenuActiveReporting from '@/assets/menu/icon_menu_active_reporting.svg';
import iconMenuActiveTrialSite from '@/assets/menu/icon_menu_active_trial_site.svg';
// Menu icons
import iconMenuClinicalAdmin from '@/assets/menu/icon_menu_default_clinical_admin.svg';
import iconMenuHci from '@/assets/menu/icon_menu_default_hci.svg';
import iconMenuPatientApproval from '@/assets/menu/icon_menu_default_patient_approval.svg';
import iconMenuPatientContact from '@/assets/menu/icon_menu_default_patient_contact.svg';
import iconMenuPatientEngagement from '@/assets/menu/icon_menu_default_patient_engagement.svg';
import iconMenuPatientLists from '@/assets/menu/icon_menu_default_patient_lists.svg';
import iconMenuPatientScreening from '@/assets/menu/icon_menu_default_patient_screening.svg';
import iconMenuReporting from '@/assets/menu/icon_menu_default_reporting.svg';
import iconMenuTrialSite from '@/assets/menu/icon_menu_default_trial_site.svg';
// Menu Hover items
import iconMenuHoverClinicalAdmin from '@/assets/menu/icon_menu_hover_clinical_admin.svg';
import iconMenuHoverHci from '@/assets/menu/icon_menu_hover_hci.svg';
import iconMenuHoverPatientApproval from '@/assets/menu/icon_menu_hover_patient_approval.svg';
import iconMenuHoverPatientContact from '@/assets/menu/icon_menu_hover_patient_contact.svg';
import iconMenuHoverPatientEngagement from '@/assets/menu/icon_menu_hover_patient_engagement.svg';
import iconMenuHoverPatientLists from '@/assets/menu/icon_menu_hover_patient_lists.svg';
import iconMenuHoverPatientScreening from '@/assets/menu/icon_menu_hover_patient_screening.svg';
import iconMenuHoverReporting from '@/assets/menu/icon_menu_hover_reporting.svg';
import iconMenuHoverTrialSite from '@/assets/menu/icon_menu_hover_trial_site.svg';

export type menuConfigObject = {
	link: string;
	title: string;
	label: string[];
	icon: {
		dashboard: string;
		nav: string;
		navActive: string;
		navHover: string;
	};
	roles?: string[];
	testId: string;
};

// if feature has no role limitation, leave role array empty
export const menuConfig = [
	{
		title: 'Health Care Institution',
		label: ['health-care-institution'],
		link: '/health-care-institution',
		icon: {
			dashboard: iconHci,
			nav: iconMenuHci,
			navActive: iconMenuActiveHci,
			navHover: iconMenuHoverHci,
		},
		roles: ['internal_study_admin'],
		testId: 'health-care-institution',
	},
	{
		title: 'Trial Sites',
		label: ['trial-sites'],
		link: '/trial-sites',
		icon: {
			dashboard: iconTrialSite,
			nav: iconMenuTrialSite,
			navActive: iconMenuActiveTrialSite,
			navHover: iconMenuHoverTrialSite,
		},
		roles: ['study_site'],
		testId: 'trial-sites',
	},
	{
		title: 'Reporting',
		link: '/reporting',
		icon: {
			dashboard: iconReporting,
			nav: iconMenuReporting,
			navActive: iconMenuActiveReporting,
			navHover: iconMenuHoverReporting,
		},
		label: [
			'study_progress_metrics',
			'patient_status_metrics',
			'survey_follow_up',
			'internal_study_admin',
			'dashboard',
		],
		roles: [
			'study_progress_metrics',
			'patient_status_metrics',
			'survey_follow_up',
			'internal_study_admin',
			'dashboard',
		],
		testId: 'reporting',
	},
	{
		title: 'Patient Approval',
		link: '/patient-approval',
		icon: {
			dashboard: iconPatientApproval,
			nav: iconMenuPatientApproval,
			navActive: iconMenuActivePatientApproval,
			navHover: iconMenuHoverPatientApproval,
		},
		label: ['patients_approval'],
		roles: ['patients_approval'],
		testId: 'patient-approval',
	},
	{
		title: 'Patient Engagement',
		label: ['patient_engagement'],
		link: '/patient-engagement',
		icon: {
			dashboard: iconPatientEngagement,
			nav: iconMenuPatientEngagement,
			navActive: iconMenuActivePatientEngagement,
			navHover: iconMenuHoverPatientEngagement,
		},
		roles: ['patient_engagement_contact'],
		testId: 'patient-engagement',
	},
	{
		title: 'Patient Screening',
		label: ['patient_screening'],
		link: '/patient-screening',
		icon: {
			dashboard: iconPatientScreening,
			nav: iconMenuPatientScreening,
			navActive: iconMenuActivePatientScreening,
			navHover: iconMenuHoverPatientScreening,
		},
		roles: ['manual_screening'],
		testId: 'patient-screening',
	},
	{
		title: 'Patient Contact',
		label: ['patient-contact'],
		link: '/patient-contact',
		icon: {
			dashboard: iconPatientContact,
			nav: iconMenuPatientContact,
			navActive: iconMenuActivePatientContact,
			navHover: iconMenuHoverPatientContact,
		},
		roles: ['patient_contact'],
		testId: 'patient-contact',
	},
	{
		title: 'Clinical Admin',
		label: ['patient_screening'],
		link: '/clinical-admin',
		icon: {
			dashboard: iconClinicalAdmin,
			nav: iconMenuClinicalAdmin,
			navActive: iconMenuAciveClinicalAdmin,
			navHover: iconMenuHoverClinicalAdmin,
		},
		roles: ['manual_screening'],
		testId: 'clinical-admin',
	},
	{
		title: 'Patient Lists',
		label: ['patient_lists'],
		link: '/patient-lists',
		icon: {
			dashboard: iconPatientList,
			nav: iconMenuPatientLists,
			navActive: iconMenuActivePatientLists,
			navHover: iconMenuHoverPatientLists,
		},
		roles: ['internal_study_admin'],
		testId: 'patient-lists',
	},
];
