import { Typography } from '@@/components/Elements';
import { CellContext, createColumnHelper } from '@tanstack/react-table';
import clsx from 'clsx';

import { DataTableObject } from '@/components/PatientDataTable';
import { formatLongDateTime } from '@/utils';

import { ResultCell } from '../components/ResultCell';
import { SurveyReviewEditCell } from '../components/SurveyReviewEditCell';
import { PatientScreeningPatientSurveyObject, FetchPatientScreeningContextObject } from '../types';

const columnHelper = createColumnHelper<DataTableObject>();

type CellContextExtended = CellContext<DataTableObject, string> &
	FetchPatientScreeningContextObject;

export const patientSurveyColumns = [
	columnHelper.accessor('survey_name', {
		header: () => <Typography variant='bodySmall'>Survey name</Typography>,
	}),
	columnHelper.accessor('survey_type', {
		id: 'type',
		header: () => <Typography variant='bodySmall'>Type</Typography>,
	}),
	columnHelper.accessor('delegated', {
		header: () => <Typography variant='bodySmall'>With assistance</Typography>,
		cell: (info) => (
			<div
				className={clsx(
					"before:content-[' '] before:inline-block before:h-[6px] before:w-[6px] before:rounded-[6px] before:mr-[10px] before:mb-[2px]",
					info.getValue() ? 'before:bg-success' : 'before:bg-error'
				)}
			>
				{info.getValue() ? 'Yes' : 'No'}
			</div>
		),
	}),
	columnHelper.accessor('expires_at', {
		header: () => <Typography variant='bodySmall'>Expires at</Typography>,
		cell: (info) => formatLongDateTime(info.getValue() as string),
	}),
	columnHelper.accessor('completed_at', {
		header: () => <Typography variant='bodySmall'>Completed at</Typography>,
		cell: (info) => (
			<span data-test-id='survey-completed-date'>
				{formatLongDateTime(info.getValue() as string)}
			</span>
		),
	}),
	columnHelper.accessor('sent_at', {
		header: () => <Typography variant='bodySmall'>Sent at</Typography>,
		cell: (info) => formatLongDateTime(info.getValue() as string),
		enableSorting: true,
	}),
	columnHelper.accessor('result', {
		header: () => <Typography variant='bodySmall'>Screening result</Typography>,
		cell: (info) => <ResultCell info={info as CellContextExtended} />,
		enableSorting: true,
	}),
	columnHelper.accessor('result_reason', {
		header: () => <Typography variant='bodySmall'>Reason</Typography>,
		cell: (info) => <div className='max-w-prose'> {info.getValue()} </div>,
	}),
	columnHelper.display({
		id: 'review_edit',
		cell: (info: unknown) => (
			<SurveyReviewEditCell info={info as CellContextExtended} isEditEnabled={true} />
		),
	}),
];

export const patientSurveyRowProps = {
	batchSelect: true,
	getRowId: (row: DataTableObject) => (row as PatientScreeningPatientSurveyObject).id,
};
