import uMedLogo from '@/assets/uMed_logo.svg';

export const Logo = () => {
	const urlPrefix = import.meta.env.VITE_URL_PREFIX;

	return (
		<a href={urlPrefix ? urlPrefix : '/'}>
			<img alt='uMed Logo' src={uMedLogo} className='h-6' />
		</a>
	);
};
